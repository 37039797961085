.startForFree {
  text-decoration: underline;
}
.startForFreeToday {
  color: inherit;
}
.heyThereFreeContainer {
  position: relative;
  line-height: 17.5px;
}
.sectionelementorSection,
.top {
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.01);
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.sectionelementorSection {
  flex: 1;
  background-color: var(--color-orange);
  height: 40px;
  align-items: center;
}
.top {
  align-self: stretch;
  background-color: var(--color-gray-700);
  height: 44px;
  align-items: flex-start;
  font-size: var(--font-size-base);
  color: inherit;
  font-family: var(--font-ibm-plex-sans);
}
.contentiifyIcon {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 561.5px);
  width: 136px;
  height: 24px;
}
.features,
.icon {
  position: relative;
}
.features {
  line-height: 14px;
}
.icon {
  width: 8.13px;
  height: 4.62px;
  mix-blend-mode: normal;
}
.platform,
.products {
  height: 72px;
  box-sizing: border-box;
}
.products {
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.01);
  width: 97.05px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-10xl) var(--padding-mini);
  align-items: center;
  justify-content: center;
  gap: var(--gap-6xs);
}
.platform {
  width: 95.75px;
  gap: var(--gap-8xs);
}
.platform,
.pricing {
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.01);
  display: flex;
  flex-direction: row;
  padding: var(--padding-10xl) var(--padding-xs);
  align-items: center;
  justify-content: center;
}
.catgory,
.catgoryWrapper {
  position: absolute;
  top: calc(50% - 36px);
  height: 72px;
}
.catgory {
  left: calc(50% - 325px);
  width: 597px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xl);
}
.catgoryWrapper {
  left: calc(50% - 391.5px);
  width: 582px;
}
.vectorIcon {
  width: 20px;
  height: 20px;
}
.english,
.icon2,
.vectorIcon {
  position: relative;
}
.english {
  line-height: 20px;
  font-weight: 500;
}
.icon2 {
  width: 8.13px;
  height: 4.62px;
}
.vectorParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.login1 {
  position: relative;
  line-height: 28px;
  font-weight: 500;
  text-align: left;
}
.login {
  position: absolute;
  height: calc(100% - 44px);
  top: 22px;
  right: 234px;
  bottom: 22px;
  width: 227px;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-4xl) 0 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-xl);
  text-align: center;
}
.contentiifyParent,
.frameWrapper {
  position: absolute;
  top: calc(50% - 36px);
  height: 72px;
}
.contentiifyParent {
  left: calc(50% - 695px);
  width: 1197px;
}
.frameWrapper {
  left: 0;
  width: 1390px;
}
.buttonactButton {
  position: absolute;
  top: 0;
  right: 0;
}
.buttonactButtonWrapper {
  position: absolute;
  height: calc(100% - 32px);
  top: 16.23px;
  right: 88px;
  bottom: 15.77px;
  width: 129px;
}
.vectorStrokeIcon {
  position: absolute;
  height: calc(100% - 51px);
  top: 22.5px;
  right: 34px;
  bottom: 28.5px;
  max-height: 100%;
  width: 21px;
  display: none;
}
.frameParent {
  position: relative;
  width: 1200px;
  height: 72px;
}
.imFuckedInner {
  align-self: stretch;
  background-color: var(--color-darkslategray-200);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  font-size: var(--font-size-base);
  font-family: var(--font-ibm-plex-sans);
}
.empowerYourBusiness {
  margin: 0;
}
.empowerYourBusinessContainer1 {
  line-break: anywhere;
  width: 100%;
}
.empowerYourBusinessContainer {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 81px;
  display: flex;
  align-items: center;
  height: 184.89px;
}
.groupChild {
  position: absolute;
  height: calc(100% - 110.89px);
  top: 98.5px;
  bottom: 12.39px;
  left: 121.5px;
  max-height: 100%;
  width: 339px;
}
.empowerYourBusinessWithVirParent {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  height: 184.89px;
}
.groupWrapper {
  position: relative;
  width: 591px;
  height: 184.89px;
}
.theSocialMedia {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 32px;
  display: flex;
  align-items: center;
}
.theSocialMediaContentMarkWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 557.5px;
  height: 64px;
}
.getViral {
  position: relative;
  line-height: 32px;
  font-weight: 500;
}
.buttonactButton1,
.tryItFree {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
}
.buttonactButton1 {
  top: 86px;
  border-radius: var(--br-8xs);
  background-color: var(--color-mediumturquoise);
  height: 48px;
  flex-direction: row;
  padding: var(--padding-5xs) 44.51000213623047px var(--padding-5xs)
    var(--padding-26xl);
  box-sizing: border-box;
  justify-content: center;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--color-darkslategray-200);
}
.tryItFree {
  width: 100%;
  top: 0;
  line-height: 17.5px;
}
.tryItFreeNoCreditCardReWrapper {
  position: absolute;
  top: 156px;
  left: 0;
  width: 557.5px;
  height: 18px;
  font-size: var(--font-size-sm);
}
.frameDiv {
  position: relative;
  width: 557.5px;
  height: 174px;
  font-size: var(--font-size-lg);
  font-family: var(--font-ibm-plex-sans);
}
.frameContainer {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 var(--padding-31xl);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.imageCrop1Icon {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  height: auto;
  object-fit: cover;
}
.imageCrop1Wrapper {
  position: relative;
  width: 614px;
  height: 620px;
  margin-left: -31px;
}
.frameGroup {
  width: 1227px;
  height: 620px;
  display: flex;
  flex-direction: row;
  padding: 0 0 0 var(--padding-10xl);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
  z-index: 1;
}
.body,
.bodyChild {
  align-self: stretch;
}
.bodyChild {
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 265px;
  flex-shrink: 0;
  object-fit: cover;
  z-index: 0;
  margin-top: -344px;
}
.body {
  background-color: var(--color-darkslategray-200);
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.01);
  height: 681px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-81xl) 0 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-end;
  mix-blend-mode: normal;
  text-align: left;
  font-size: var(--font-size-29xl);
  color: var(--color-gray-100);
}
.contentIntelligence {
  color: var(--color-mediumturquoise);
}
.the1ContentContainer {
  position: relative;
  line-height: 52px;
  display: inline-block;
  width: 676px;
}
.contentify251 {
  position: absolute;
  top: 0;
  left: calc(50% - 479px);
  width: 958px;
  height: 349px;
  object-fit: cover;
}
.groupItem,
.spy100cContents {
  position: absolute;
  transform: rotate(-6.89deg);
  transform-origin: 0 0;
}
.groupItem {
  top: 24.83px;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  border-top: 1px solid var(--color-mediumvioletred);
  border-right: 1px solid var(--color-mediumvioletred);
  border-bottom: 5px solid var(--color-mediumvioletred);
  border-left: 1px solid var(--color-mediumvioletred);
  box-sizing: border-box;
  width: 207px;
  height: 59.27px;
}
.spy100cContents {
  top: calc(50% - 2.64px);
  left: calc(50% - 92.3px);
}
.groupContainer,
.rectangleParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 212.61px;
  height: 83.67px;
}
.groupContainer {
  top: 216.17px;
  left: 648.5px;
}
.contentify251Parent {
  position: relative;
  width: 958px;
  height: 349px;
  font-size: var(--font-size-xl);
  color: var(--color-mediumvioletred);
  font-family: var(--font-roboto);
}
.bodyTrending,
.the1ContentIntelligenceParent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.the1ContentIntelligenceParent {
  justify-content: flex-start;
  gap: var(--gap-17xl);
}
.bodyTrending {
  align-self: stretch;
  background-color: var(--color-whitesmoke-400);
  height: 750px;
  padding: var(--padding-58xl) var(--padding-115xl);
  box-sizing: border-box;
  justify-content: center;
  color: var(--color-gray-900);
}
.forInsights {
  margin: 0;
  color: var(--color-gray-900);
}
.spyYourCompetitorContainer {
  position: absolute;
  top: 0;
  left: calc(50% - 316.5px);
  line-height: 52px;
}
.spyYourCompetitorSAudiencWrapper {
  position: relative;
  width: 1122px;
  height: 116px;
}
.contentify11 {
  position: absolute;
  top: 0;
  left: 0;
  width: 956px;
  height: 327px;
  object-fit: cover;
}
.groupInner {
  position: absolute;
  top: 35.92px;
  left: -1.85px;
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  border-top: 1px solid var(--color-mediumaquamarine-100);
  border-right: 1px solid var(--color-mediumaquamarine-100);
  border-bottom: 5px solid var(--color-mediumaquamarine-100);
  border-left: 1px solid var(--color-mediumaquamarine-100);
  box-sizing: border-box;
  width: 242.98px;
  height: 81.54px;
  transform: rotate(-5.56deg);
  transform-origin: 0 0;
}
.audienceAnalyticsForContainer {
  position: absolute;
  top: calc(50% - 16.62px);
  left: calc(50% - 124.99px);
  display: flex;
  align-items: center;
  width: 232.54px;
  height: 63.28px;
  transform: rotate(-5.31deg);
  transform-origin: 0 0;
}
.rectangleGroup {
  position: absolute;
  top: 136.09px;
  left: 230.11px;
  width: 252.38px;
  height: 116.61px;
  transform: rotate(2.96deg);
  transform-origin: 0 0;
}
.contentify11Parent {
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 2px;
  width: 956px;
  height: 327px;
  z-index: 0;
}
.bodyAbtesting,
.frameParent1,
.groupFrame {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.groupFrame {
  width: 960px;
  height: 333px;
  justify-content: flex-start;
  position: relative;
  font-size: var(--font-size-xl);
  color: var(--color-mediumaquamarine);
  font-family: var(--font-roboto);
}
.bodyAbtesting,
.frameParent1 {
  justify-content: center;
}
.frameParent1 {
  gap: var(--gap-17xl);
}
.bodyAbtesting {
  align-self: stretch;
  background-color: var(--color-whitesmoke-400);
  height: 750px;
  padding: var(--padding-49xl) var(--padding-114xl);
  box-sizing: border-box;
  color: var(--color-mediumturquoise);
}
.span {
  color: var(--color-mediumblue);
}
.tailorContentsBasedContainer {
  position: absolute;
  top: 0.36px;
  left: calc(50% - 277px);
  line-height: 52px;
}
.contentify22,
.tailorContentsBasedOnHotTWrapper {
  position: relative;
  width: 722px;
  height: 104px;
}
.contentify22 {
  border-radius: var(--br-4xs);
  width: 962px;
  height: 337px;
  object-fit: cover;
  z-index: 0;
}
.autoGenerateVideos,
.rectangleDiv {
  position: absolute;
  transform-origin: 0 0;
}
.rectangleDiv {
  top: 35.92px;
  left: -1.85px;
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  border-top: 1px solid var(--color-mediumvioletred);
  border-right: 1px solid var(--color-mediumvioletred);
  border-bottom: 5px solid var(--color-mediumvioletred);
  border-left: 1px solid var(--color-mediumvioletred);
  box-sizing: border-box;
  width: 242.98px;
  height: 81.54px;
  transform: rotate(-5.56deg);
}
.autoGenerateVideos {
  top: calc(50% - 16.62px);
  left: calc(50% - 121.99px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 232.54px;
  height: 63.28px;
  transform: rotate(-5.31deg);
}
.rectangleContainer {
  position: absolute;
  margin: 0 !important;
  top: 208.5px;
  left: 233.11px;
  width: 252.38px;
  height: 116.61px;
  transform: rotate(2.96deg);
  transform-origin: 0 0;
  z-index: 1;
}
.contentify22Parent {
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-7xl);
  font-size: var(--font-size-xl);
  color: var(--color-mediumvioletred);
  font-family: var(--font-roboto);
}
.bodyHottopic,
.contentify22Parent,
.frameParent2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.frameParent2 {
  justify-content: flex-start;
  gap: var(--gap-17xl);
}
.bodyHottopic {
  align-self: stretch;
  background-color: var(--color-whitesmoke-400);
  height: 750px;
  padding: var(--padding-52xl) var(--padding-113xl);
  box-sizing: border-box;
  justify-content: center;
}
.bodyHottopic,
.yourContents {
  color: var(--color-gray-900);
}
.scheduleYourContentsContainer {
  position: absolute;
  top: 0;
  left: calc(50% - 299px);
  line-height: 52px;
}
.contentify42,
.scheduleYourContentsAheadWrapper {
  position: relative;
  width: 722px;
  height: 52px;
}
.contentify42 {
  width: 956px;
  height: 333px;
  object-fit: cover;
  z-index: 0;
}
.groupChild1 {
  top: 31.35px;
  left: -1.62px;
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  border-top: 1px solid var(--color-mediumaquamarine-100);
  border-right: 1px solid var(--color-mediumaquamarine-100);
  border-bottom: 5px solid var(--color-mediumaquamarine-100);
  border-left: 1px solid var(--color-mediumaquamarine-100);
  box-sizing: border-box;
  width: 219.44px;
  height: 81.6px;
  transform: rotate(-5.27deg);
}
.getThingsPlanned,
.groupChild1,
.groupDiv {
  position: absolute;
  transform-origin: 0 0;
}
.getThingsPlanned {
  top: calc(50% - 19.87px);
  left: calc(50% - 101.49px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 184.98px;
  height: 63.33px;
  transform: rotate(-5.03deg);
}
.groupDiv {
  margin: 0 !important;
  top: 191px;
  left: 515.77px;
  width: 229.68px;
  height: 112.03px;
  transform: rotate(2.96deg);
  z-index: 1;
}
.bodySchedule,
.contentify42Parent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contentify42Parent {
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-7xl);
  font-size: var(--font-size-xl);
  color: var(--color-mediumaquamarine);
  font-family: var(--font-roboto);
}
.bodySchedule {
  align-self: stretch;
  background-color: var(--color-whitesmoke-400);
  height: 750px;
  padding: var(--padding-43xl) var(--padding-116xl);
  box-sizing: border-box;
  justify-content: center;
  color: var(--color-mediumturquoise);
}
.forSeamlessMarketing {
  font-size: var(--font-size-19xl);
  color: var(--color-white);
}
.aiPoweredForContainer {
  position: relative;
  line-height: 45px;
  display: flex;
  align-items: center;
  width: 517px;
}
.pseudo {
  position: absolute;
  top: -11px;
  left: 1px;
  background-color: var(--color-mediumblue);
  width: 248px;
  height: 11px;
}
.reach,
.targetToThose {
  display: flex;
  align-items: center;
}
.reach {
  position: absolute;
  top: 14px;
  left: 17px;
  line-height: 16px;
  width: 46.65px;
  height: 20px;
}
.targetToThose {
  position: relative;
  line-height: 20px;
  width: 208px;
  flex-shrink: 0;
}
.hashtagAnalytics,
.span1 {
  position: absolute;
  display: flex;
}
.span1 {
  top: 48px;
  left: 17px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-sm);
}
.hashtagAnalytics {
  top: 57px;
  left: 1px;
  line-height: 30px;
  font-weight: 500;
  align-items: center;
  width: 208px;
}
.audienceDemographicsAnalytic,
.socialIntelligenceFacebook {
  position: absolute;
  top: 98px;
  left: 0;
  line-height: 30px;
  font-weight: 500;
}
.socialIntelligenceFacebook {
  top: 0;
  line-height: 23px;
  display: flex;
  align-items: center;
  width: 208px;
}
.timeSeriesAnalytics {
  position: absolute;
  top: 139px;
  left: 0;
  line-height: 30px;
  font-weight: 500;
}
.ulelementorIconListItems {
  position: absolute;
  top: 140.93px;
  left: 17px;
  width: 216px;
  height: 138px;
  font-size: var(--font-size-sm-6);
  color: var(--color-gray-600);
}
.divelementorWidgetWrap {
  position: relative;
  background-color: var(--color-white);
  border-right: 1px solid var(--color-lavender);
  border-bottom: 1px solid var(--color-lavender);
  border-left: 1px solid var(--color-lavender);
  box-sizing: border-box;
  width: 250px;
  height: 327px;
}
.pseudo1 {
  position: absolute;
  top: -11px;
  left: 1px;
  background-color: var(--color-mediumturquoise);
  width: 248px;
  height: 11px;
}
.growYourRelationshipContainer,
.nurture {
  position: absolute;
  top: 14px;
  left: 17px;
  line-height: 16px;
  display: flex;
  align-items: center;
  width: 58.9px;
  height: 20px;
}
.growYourRelationshipContainer {
  top: 1px;
  left: 0;
  line-height: 20px;
  width: 173.04px;
  height: 58px;
}
.p {
  position: absolute;
  top: 48px;
  left: 17px;
  width: 184px;
  height: 60px;
  font-size: var(--font-size-sm);
}
.aiSupportedContents,
.suggestedMessaging {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 30px;
  font-weight: 500;
}
.suggestedMessaging {
  top: 36.93px;
}
.contentScheduling,
.performanceMonitoring {
  position: absolute;
  top: 73.93px;
  left: 0;
  line-height: 23px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 208px;
}
.contentScheduling {
  top: 126.93px;
  width: 207px;
}
.ulelementorIconListItems1 {
  position: absolute;
  top: 133px;
  left: 17px;
  width: 216px;
  height: 174px;
  font-size: var(--font-size-sm-6);
  color: var(--color-gray-600);
}
.convert {
  position: absolute;
  top: 14px;
  left: 17px;
  line-height: 16px;
  display: flex;
  align-items: center;
  width: 59.18px;
  height: 20px;
}
.attributionAnalytics,
.provideIndividualAudienceContainer {
  position: absolute;
  top: 1px;
  left: 0;
  line-height: 20px;
  display: flex;
  align-items: center;
  width: 172.29px;
  height: 58px;
}
.attributionAnalytics {
  top: 42px;
  line-height: 30px;
  font-weight: 500;
  width: 161.47px;
  height: 18px;
}
.ulelementorIconListItems2 {
  position: absolute;
  top: 141.93px;
  left: 17px;
  width: 216px;
  height: 67px;
  font-size: var(--font-size-sm-6);
  color: var(--color-gray-600);
}
.grow,
.pseudo3 {
  position: absolute;
}
.pseudo3 {
  top: -11px;
  left: 1px;
  background-color: var(--color-mediumaquamarine);
  width: 248px;
  height: 11px;
}
.grow {
  top: 14px;
  left: 17px;
  line-height: 16px;
  display: flex;
  align-items: center;
  width: 40.42px;
  height: 20px;
}
.delightEachCustomerContainer {
  position: absolute;
  top: 0.93px;
  left: 0;
  line-height: 20px;
  display: inline-block;
  width: 201px;
  height: 58px;
}
.aiSeeding,
.leadScoring {
  position: absolute;
  left: 0;
  line-height: 30px;
  font-weight: 500;
}
.aiSeeding {
  top: 6px;
  display: flex;
  align-items: center;
  width: 88.86px;
  height: 18px;
}
.leadScoring {
  top: 36px;
}
.ulelementorIconListItems3 {
  position: absolute;
  top: 136px;
  left: 17px;
  width: 216px;
  height: 174px;
  font-size: var(--font-size-sm-6);
  color: var(--color-gray-600);
}
.divelementorWidgetWrapParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-gray-400);
  font-family: var(--font-ibm-plex-sans);
}
.aiPoweredForSeamlessMarketParent {
  height: 454px;
  justify-content: flex-start;
  gap: var(--gap-41xl);
}
.aiPoweredForSeamlessMarketParent,
.body1,
.stage {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stage {
  height: 478px;
  justify-content: flex-start;
}
.body1 {
  align-self: stretch;
  border-radius: var(--br-29xl) var(--br-29xl) 0 0;
  background-color: var(--color-darkslategray-200);
  padding: var(--padding-76xl) var(--padding-64xl);
  justify-content: center;
  font-size: var(--font-size-21xl);
  color: var(--color-mediumturquoise);
}
.div,
.svgIcon {
  position: absolute;
  height: 24px;
}
.div {
  top: 202px;
  left: 109.7px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 8.95px;
}
.svgIcon {
  top: 0;
  left: 0;
  width: 24px;
  overflow: hidden;
}
.freeHelpCoursesContainer {
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.h3elementorFlipBoxLayer {
  position: absolute;
  top: 48px;
  left: 0;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-53xl) 0 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.getStarted {
  position: absolute;
  top: 178px;
  left: 0;
  line-height: 24px;
}
.divelementorFlipBoxLayer1 {
  position: absolute;
  height: calc(100% - 48px);
  top: 24px;
  bottom: 24px;
  left: 24px;
  width: 202px;
  font-size: var(--font-size-base);
  font-family: var(--font-ibm-plex-sans);
}
.divelementorFlipBoxLayer {
  position: absolute;
  top: calc(50% + 84px);
  left: calc(50% + 280px);
  background-color: var(--color-mediumturquoise);
  width: 250px;
  height: 250px;
}
.div1 {
  position: absolute;
  top: 202px;
  left: 126.2px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 8.95px;
  height: 24px;
}
.h3elementorFlipBoxLayer1 {
  position: absolute;
  top: 48px;
  left: 0;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-32xl) 0 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.divelementorFlipBoxLayer2 {
  position: absolute;
  top: calc(50% + 84px);
  left: calc(50% + 10px);
  background-color: var(--color-mediumturquoise);
  width: 250px;
  height: 250px;
}
.div2 {
  position: absolute;
  top: 202px;
  left: 112.97px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 8.95px;
  height: 24px;
}
.h3elementorFlipBoxLayer2 {
  position: absolute;
  top: 48px;
  left: 0;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-70xl) 0 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.divelementorFlipBoxLayer4 {
  position: absolute;
  top: calc(50% + 84px);
  left: calc(50% - 260px);
  background-color: var(--color-mediumturquoise);
  width: 250px;
  height: 250px;
}
.div3 {
  position: absolute;
  top: 202px;
  left: 128.34px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 8.95px;
  height: 24px;
}
.h3elementorFlipBoxLayer3 {
  position: absolute;
  top: 48px;
  left: 0;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-2xl) 0 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.divelementorFlipBoxLayer6 {
  position: absolute;
  top: calc(50% + 84px);
  left: calc(50% - 530px);
  background-color: var(--color-mediumturquoise);
  width: 250px;
  height: 250px;
}
.div4,
.landingPages {
  position: absolute;
  line-height: 24px;
}
.div4 {
  top: 202px;
  left: 125.33px;
  display: flex;
  align-items: center;
  width: 8.95px;
  height: 24px;
}
.landingPages {
  top: 48px;
  left: 0;
  font-weight: 500;
}
.divelementorFlipBoxLayer8 {
  position: absolute;
  top: calc(50% - 186px);
  left: calc(50% + 280px);
  background-color: var(--color-mediumturquoise);
  width: 250px;
  height: 250px;
}
.div5 {
  position: absolute;
  top: 202px;
  left: 139.23px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 8.95px;
  height: 24px;
}
.divelementorFlipBoxLayer10 {
  position: absolute;
  top: calc(50% - 186px);
  left: calc(50% + 10px);
  background-color: var(--color-mediumturquoise);
  width: 250px;
  height: 250px;
}
.h3elementorFlipBoxLayer4 {
  position: absolute;
  top: 48px;
  left: 0;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-44xl) 0 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.divelementorFlipBoxLayer12 {
  position: absolute;
  top: calc(50% - 186px);
  left: calc(50% - 260px);
  background-color: var(--color-mediumturquoise);
  width: 250px;
  height: 250px;
}
.div7 {
  position: absolute;
  top: 202px;
  left: 140.75px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 8.95px;
  height: 24px;
}
.divelementorFlipBoxLayer14 {
  position: absolute;
  top: calc(50% - 186px);
  left: calc(50% - 530px);
  background-color: var(--color-mediumturquoise);
  width: 250px;
  height: 250px;
}
.everythingYouNeedContainer {
  position: absolute;
  top: calc(50% - 334px);
  left: calc(50% - 337.41px);
  font-size: var(--font-size-21xl);
  line-height: 48px;
  font-family: var(--font-poppins);
  text-align: center;
}
.content3 {
  position: absolute;
  top: calc(50% - 332px);
  left: calc(50% - 530px);
  width: 1060px;
  height: 668px;
}
.content3Wrapper {
  position: relative;
  width: 1060px;
  height: 672px;
}
.body2 {
  align-self: stretch;
  background-color: var(--color-darkslategray-200);
  height: 773px;
  display: flex;
  flex-direction: row;
  padding: 0 0 var(--padding-17xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-size: var(--font-size-sm);
  font-family: var(--font-font-awesome-5-free);
}
.teamUpWithContainer,
.with870IntegrationsContainer {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
}
.teamUpWithContainer {
  top: 0;
  line-height: 40px;
  font-family: var(--font-poppins);
  width: 305.68px;
  height: 85px;
}
.with870IntegrationsContainer {
  top: 101px;
  font-size: var(--font-size-lg);
  line-height: 28px;
  width: 336.62px;
  height: 107px;
}
.viewAllApps {
  position: absolute;
  top: 231px;
  left: 5px;
  font-size: var(--font-size-base);
  line-height: 24px;
  font-weight: 500;
}
.div8 {
  position: absolute;
  top: 237px;
  left: 105.89px;
  font-size: var(--font-size-sm);
  line-height: 14px;
  font-family: var(--font-font-awesome-5-free);
  color: var(--color-darkslategray-200);
  text-align: right;
}
.appsGroup768x366pngIcon {
  position: absolute;
  top: calc(50% - 137.74px);
  left: calc(50% - 76.19px);
  width: 584.38px;
  height: 278.48px;
  object-fit: cover;
}
.teamUpWithYourFavoriteAppParent {
  position: relative;
  width: 1016.38px;
  height: 281.48px;
}
.body3,
.customerSpotlights {
  display: flex;
  align-items: center;
  justify-content: center;
}
.body3 {
  align-self: stretch;
  border-radius: 0 0 var(--br-29xl) var(--br-29xl);
  background-color: var(--color-darkslategray-200);
  height: 367px;
  flex-direction: column;
  padding: var(--padding-4xl) var(--padding-85xl) var(--padding-13xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-13xl);
  font-family: var(--font-ibm-plex-sans);
}
.customerSpotlights {
  position: absolute;
  bottom: 567.49px;
  left: calc(50% - 206.78px);
  font-size: var(--font-size-21xl);
  line-height: 54px;
  font-family: var(--font-poppins);
  color: var(--color-gray-600);
  text-align: center;
  width: 421.76px;
  height: 56px;
}
.child,
.hipcookspngIcon {
  position: absolute;
  width: 251px;
}
.child {
  top: 0.05px;
  left: 0;
  background-color: var(--color-white);
  height: 444px;
}
.hipcookspngIcon {
  top: calc(50% - 214.24px);
  left: calc(50% - 125.5px);
  height: 192.33px;
  object-fit: cover;
}
.hipcooks,
.localizingOurContentContainer {
  position: absolute;
  top: 210.38px;
  left: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 69.47px;
  height: 20px;
}
.localizingOurContentContainer {
  top: 250.43px;
  width: 204.01px;
  height: 116px;
}
.div11,
.seeMore {
  position: absolute;
  color: var(--color-mediumblue);
}
.seeMore {
  top: 404.47px;
  left: 21px;
  line-height: 24px;
  font-weight: 500;
}
.div11 {
  top: 410.47px;
  left: 94.22px;
  font-size: var(--font-size-sm);
  line-height: 14px;
  font-family: var(--font-font-awesome-5-free);
  text-align: right;
}
.div10 {
  top: calc(50% - 222.02px);
  left: calc(50% - 125.5px);
  height: 428.47px;
}
.div10,
.div9,
.msi768x589pngIcon {
  position: absolute;
  width: 251px;
}
.div9 {
  top: 103px;
  left: 0;
  height: 444.05px;
}
.msi768x589pngIcon {
  top: calc(50% - 214.25px);
  left: calc(50% - 125.5px);
  height: 192.48px;
  object-fit: cover;
}
.activecampaignLetsUsContainer,
.museumOfScienceContainer {
  position: absolute;
  top: 210.49px;
  left: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 144.54px;
  height: 44px;
}
.activecampaignLetsUsContainer {
  top: 274.49px;
  width: 206.15px;
  height: 92px;
}
.div14,
.seeMore1 {
  position: absolute;
  color: var(--color-mediumblue);
}
.seeMore1 {
  top: 404.49px;
  left: 21px;
  line-height: 24px;
  font-weight: 500;
}
.div14 {
  top: 410.49px;
  left: 94.22px;
  font-size: var(--font-size-sm);
  line-height: 14px;
  font-family: var(--font-font-awesome-5-free);
  text-align: right;
}
.div12,
.div13 {
  position: absolute;
  width: 251px;
}
.div13 {
  top: calc(50% - 222.02px);
  left: calc(50% - 125.5px);
  height: 428.49px;
}
.div12 {
  top: 103px;
  left: 259px;
  height: 444.05px;
}
.evenThoughImContainer,
.marrowAudio {
  position: absolute;
  top: 210.49px;
  left: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 105.18px;
  height: 20px;
}
.evenThoughImContainer {
  top: 250.49px;
  width: 207.43px;
  height: 116px;
}
.beefy768x589pngIcon,
.div15 {
  position: absolute;
  width: 251px;
}
.div15 {
  top: 103px;
  left: 518px;
  height: 444.05px;
}
.beefy768x589pngIcon {
  top: calc(50% - 214.24px);
  left: calc(50% - 125.5px);
  height: 192.48px;
  object-fit: cover;
}
.activecampaignHadTheContainer,
.beefyMarketing {
  position: absolute;
  top: 215.82px;
  left: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 122.65px;
  height: 20px;
}
.activecampaignHadTheContainer {
  top: 261.14px;
  width: 218.98px;
  height: 116px;
}
.div18 {
  position: absolute;
  top: 103px;
  left: 777px;
  width: 251px;
  height: 444.05px;
}
.seeAllCustomer {
  top: 0;
  left: 0;
  line-height: 24px;
  font-weight: 500;
}
.div21,
.seeAllCustomer,
.seemore {
  position: absolute;
}
.div21 {
  top: 6px;
  left: 220.61px;
  font-size: var(--font-size-sm);
  line-height: 14px;
  font-family: var(--font-font-awesome-5-free);
  text-align: right;
}
.seemore {
  top: 599.49px;
  left: 401.81px;
  width: 229.61px;
  height: 24px;
  text-align: center;
  color: var(--color-gray-600);
}
.customerSpotlightsParent {
  position: relative;
  width: 1028px;
  height: 623.49px;
  z-index: 0;
}
.testimonial {
  align-self: stretch;
  background-color: var(--color-whitesmoke-100);
  height: 721px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-darkslategray-100);
  font-family: var(--font-ibm-plex-sans);
}
.noCreditCard,
.tryItNow {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 48px;
  display: flex;
  align-items: center;
  width: 364.58px;
  height: 56px;
}
.noCreditCard {
  top: 70px;
  font-size: var(--font-size-lg);
  line-height: 28px;
  font-family: var(--font-ibm-plex-sans);
  width: 308.56px;
  height: 23px;
}
.tryItNowForFreeParent {
  position: relative;
  width: 364.58px;
  height: 93px;
}
.emailAddress {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 126.31px;
  height: 25px;
}
.divplaceholder {
  position: absolute;
  top: 19.5px;
  left: 16px;
  width: 274.47px;
  height: 25px;
  overflow: hidden;
}
.inputacInput {
  position: absolute;
  width: calc(100% - 233.53px);
  top: 0;
  right: 233.53px;
  left: 0;
  border-radius: var(--br-8xs) 0 0 var(--br-8xs);
  background-color: var(--color-white);
  height: 64px;
}
.startYourFree {
  position: relative;
  line-height: 32px;
}
.buttonactButton2 {
  position: absolute;
  top: 0;
  left: 306.47px;
  border-radius: 0 var(--br-8xs) var(--br-8xs) 0;
  background-color: var(--color-mediumblue);
  display: flex;
  flex-direction: row;
  padding: var(--padding-base) 35.47999954223633px var(--padding-base)
    35.04999923706055px;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  color: var(--color-white);
}
.inputacInputParent {
  position: relative;
  width: 540px;
  height: 64px;
  font-size: var(--font-size-lgi-2);
  color: var(--color-lightslategray);
  font-family: var(--font-ibm-plex-sans);
}
.frameParent4 {
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-156xl);
}
.body4,
.frameParent4,
.ourCustomersLove {
  display: flex;
  align-items: center;
}
.body4 {
  align-self: stretch;
  background-color: var(--color-darkslategray-200);
  flex-direction: column;
  padding: var(--padding-25xl) var(--padding-54xl);
  justify-content: flex-start;
  text-align: left;
  font-size: var(--font-size-21xl);
}
.ourCustomersLove {
  position: relative;
  line-height: 61.6px;
  font-family: var(--font-poppins);
  justify-content: center;
  width: 504.93px;
  height: 61px;
  flex-shrink: 0;
}
.divelementorContainerIcon {
  position: relative;
  width: 1080px;
  height: 142px;
}
.dontJustTakeContainer {
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 595.64px;
  height: 51px;
  flex-shrink: 0;
}
.seeReviews {
  position: absolute;
  top: 0;
  left: -1px;
  line-height: 32px;
  font-weight: 500;
}
.div22 {
  position: absolute;
  top: 10px;
  left: 113.79px;
  font-size: var(--font-size-sm);
  line-height: 14px;
  font-family: var(--font-font-awesome-5-free);
  text-align: right;
}
.a {
  position: relative;
  width: 125.53px;
  height: 32px;
  font-size: var(--font-size-xl);
  color: var(--color-mediumblue);
}
.divelementorWidgetWrap4,
.testimonial1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.divelementorWidgetWrap4 {
  gap: var(--gap-xl);
}
.testimonial1 {
  align-self: stretch;
  background-color: var(--color-gainsboro);
  padding: var(--padding-22xl) var(--padding-54xl);
  font-size: var(--font-size-25xl);
  color: var(--color-darkslategray-100);
  font-family: var(--font-ibm-plex-sans);
}
.supportingYouAtContainer {
  position: relative;
  line-height: 28px;
}
.h4elementorHeadingTitle {
  display: flex;
  flex-direction: column;
  padding: var(--padding-11xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-lg);
}
.reach1 {
  position: relative;
  letter-spacing: 0.6px;
  line-height: 18px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  width: 42.93px;
  height: 15px;
  flex-shrink: 0;
}
.createForms,
.emailNewsletter,
.emailTemplates,
.leadGeneration,
.popUpBuilder {
  position: absolute;
  top: 5px;
  left: 0;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 104.45px;
  height: 18px;
}
.createForms,
.emailNewsletter,
.leadGeneration,
.popUpBuilder {
  top: 43px;
  width: 107.7px;
}
.createForms,
.leadGeneration,
.popUpBuilder {
  top: 81px;
  width: 84.48px;
}
.leadGeneration,
.popUpBuilder {
  top: 119px;
  width: 93.93px;
}
.leadGeneration {
  top: 157px;
  width: 103.48px;
}
.ulelementorIconListItems4 {
  position: relative;
  width: 216px;
  height: 180px;
  font-size: var(--font-size-sm);
  color: var(--color-dimgray);
}
.divelementorWidgetWrap5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-2xl);
}
.nurture1 {
  position: relative;
  letter-spacing: 0.6px;
  line-height: 18px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  width: 59.93px;
  height: 15px;
  flex-shrink: 0;
}
.conversationalMarketing,
.facebookAdvertising,
.leadTrackingSoftware,
.socialMediaAdvertising,
.wordpress {
  position: absolute;
  top: 5px;
  left: 0;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 159.84px;
  height: 18px;
}
.facebookAdvertising,
.leadTrackingSoftware,
.socialMediaAdvertising,
.wordpress {
  top: 43px;
  width: 134.9px;
}
.leadTrackingSoftware,
.socialMediaAdvertising,
.wordpress {
  top: 81px;
  width: 153.59px;
}
.leadTrackingSoftware,
.wordpress {
  top: 119px;
  width: 146.37px;
}
.wordpress {
  top: 5px;
  left: 22.5px;
  width: 67.92px;
}
.a1,
.svgIcon8 {
  position: absolute;
  left: 0;
}
.svgIcon8 {
  top: 7px;
  width: 14px;
  height: 14px;
  overflow: hidden;
}
.a1 {
  width: 100%;
  top: calc(50% + 62px);
  right: 0;
  height: 28px;
}
.convertGrow,
.salesPlatform {
  display: flex;
  align-items: center;
}
.convertGrow {
  position: relative;
  letter-spacing: 0.6px;
  line-height: 18px;
  text-transform: uppercase;
  width: 114.29px;
  height: 15px;
  flex-shrink: 0;
}
.salesPlatform {
  position: absolute;
  top: 5px;
  left: 0;
  line-height: 28px;
  width: 91.65px;
  height: 18px;
}
.salesAnalytics {
  top: 43px;
  display: flex;
  align-items: center;
  width: 94.76px;
}
.attributionSoftware,
.leadScoring1,
.salesAnalytics {
  position: absolute;
  left: 0;
  line-height: 28px;
  height: 18px;
}
.leadScoring1 {
  top: 81px;
  display: flex;
  align-items: center;
  width: 80.58px;
}
.attributionSoftware {
  top: 119px;
  width: 127.25px;
}
.attributionSoftware,
.ecommerce,
.salesforce {
  display: flex;
  align-items: center;
}
.salesforce {
  position: absolute;
  top: 5px;
  left: 22.5px;
  line-height: 28px;
  width: 66.09px;
  height: 18px;
}
.ecommerce {
  position: relative;
  letter-spacing: 0.6px;
  line-height: 18px;
  text-transform: uppercase;
  width: 79.12px;
  height: 15px;
  flex-shrink: 0;
}
.ecommerceMarketing,
.ecommercePersonalization,
.shoppingCartAbandonment {
  position: absolute;
  top: 5px;
  left: 0;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 140.75px;
  height: 18px;
}
.ecommercePersonalization,
.shoppingCartAbandonment {
  top: 43px;
  width: 174.78px;
}
.shoppingCartAbandonment {
  top: 81px;
  width: 181.31px;
}
.a3,
.shopify {
  position: absolute;
}
.shopify {
  top: 5px;
  left: 22.5px;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 47.42px;
  height: 18px;
}
.a3 {
  width: 100%;
  top: calc(50% + 24px);
  right: 0;
  left: 0;
  height: 28px;
}
.woocommerce {
  position: absolute;
  top: 5px;
  left: 22.5px;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 96.53px;
  height: 18px;
}
.divelementorContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-3xl);
}
.touachpoint {
  align-self: stretch;
  background-color: var(--color-aliceblue);
  display: flex;
  flex-direction: column;
  padding: var(--padding-46xl) var(--padding-43xl);
  align-items: center;
  justify-content: flex-start;
}
.customerExperienceAutomationContainer {
  position: relative;
  letter-spacing: 0.6px;
  line-height: 18px;
  text-transform: uppercase;
}
.a5 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.emailMarketingAutomation,
.marketingAutomation,
.salesAutomation,
.salesEngagement,
.serviceSupport {
  position: absolute;
  top: 5px;
  left: 0;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 178.08px;
  height: 18px;
}
.marketingAutomation,
.salesAutomation,
.salesEngagement,
.serviceSupport {
  top: 43px;
  width: 139.61px;
}
.salesAutomation,
.salesEngagement,
.serviceSupport {
  top: 81px;
  width: 110.97px;
}
.salesEngagement,
.serviceSupport {
  top: 119px;
  width: 116.14px;
}
.serviceSupport {
  top: 157px;
  width: 112.93px;
}
.divelementorWidgetWrap9 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lgi);
}
.landingPages1 {
  position: absolute;
  top: 5px;
  left: 0;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 90.53px;
  height: 18px;
}
.liveChat,
.smsMarketing,
.socialMediaMarketing,
.transactionalEmail,
.webPersonalization {
  position: absolute;
  top: 43px;
  left: 0;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 57.53px;
  height: 18px;
}
.smsMarketing,
.socialMediaMarketing,
.transactionalEmail,
.webPersonalization {
  top: 81px;
  width: 93.72px;
}
.socialMediaMarketing,
.transactionalEmail,
.webPersonalization {
  top: 119px;
  width: 145.33px;
}
.transactionalEmail,
.webPersonalization {
  top: 157px;
  width: 128.18px;
}
.transactionalEmail {
  top: 195px;
  width: 123.39px;
}
.ulelementorIconListItems9 {
  position: relative;
  width: 216px;
  height: 218px;
  font-size: var(--font-size-sm);
  color: var(--color-dimgray);
}
.a7 {
  display: flex;
  flex-direction: row;
  padding: 0 0.1999969482421875px 0 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.getStarted1,
.liveExpertWorkshop {
  position: absolute;
  top: 5px;
  left: 0;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 72.23px;
  height: 18px;
}
.liveExpertWorkshop {
  top: 43px;
  width: 136.17px;
}
.blog,
.community,
.events,
.helpResources,
.marketingGlossary,
.productUpdates,
.strategySession {
  position: absolute;
  top: 81px;
  left: 0;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 128.97px;
  height: 18px;
}
.blog,
.community,
.events,
.helpResources,
.marketingGlossary,
.productUpdates {
  top: 119px;
  width: 72.5px;
}
.blog,
.events,
.helpResources,
.marketingGlossary,
.productUpdates {
  top: 157px;
  width: 42.51px;
}
.blog,
.helpResources,
.marketingGlossary,
.productUpdates {
  top: 195px;
  width: 98.61px;
}
.blog,
.marketingGlossary,
.productUpdates {
  top: 233px;
  width: 105.36px;
}
.blog,
.marketingGlossary {
  top: 271px;
  width: 120.75px;
}
.blog {
  top: 309px;
  width: 28.39px;
}
.ulelementorIconListItems10 {
  position: relative;
  width: 216px;
  height: 332px;
  font-size: var(--font-size-sm);
  color: var(--color-dimgray);
}
.a8 {
  display: flex;
  flex-direction: row;
  padding: 0 0.4199981689453125px 0 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.automationRecipes,
.developerCenter,
.freeTools,
.integrationMarketplace {
  position: absolute;
  top: 5px;
  left: 0;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 151.53px;
  height: 18px;
}
.automationRecipes,
.developerCenter,
.freeTools {
  top: 43px;
  width: 109.54px;
}
.automationRecipes,
.freeTools {
  top: 81px;
  width: 64.9px;
}
.automationRecipes {
  top: 119px;
  width: 126.64px;
}
.ulelementorIconListItems11 {
  position: relative;
  width: 216px;
  height: 142px;
  font-size: var(--font-size-sm);
  color: var(--color-dimgray);
}
.becomeAPartner {
  position: relative;
  letter-spacing: 0.6px;
  line-height: 18px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  width: 126.4px;
  height: 15px;
  flex-shrink: 0;
}
.affiliate,
.agencyReseller,
.isvDeveloper {
  position: absolute;
  top: 5px;
  left: 0;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 113.79px;
  height: 18px;
}
.affiliate,
.isvDeveloper {
  top: 43px;
  width: 48.98px;
}
.isvDeveloper {
  top: 81px;
  width: 102.81px;
}
.ulelementorIconListItems12 {
  position: relative;
  width: 216px;
  height: 104px;
  font-size: var(--font-size-sm);
  color: var(--color-dimgray);
}
.divelementorWidgetWrap12 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-96xl);
}
.howWeCompare,
.whyActivecampaign {
  position: absolute;
  top: 5px;
  left: 0;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 132.53px;
  height: 18px;
}
.howWeCompare {
  top: 43px;
  width: 112.29px;
}
.customerStories {
  top: 81px;
  display: flex;
  align-items: center;
  width: 108.59px;
}
.careers,
.customerStories,
.faq {
  position: absolute;
  left: 0;
  line-height: 28px;
  height: 18px;
}
.careers {
  top: 119px;
  display: flex;
  align-items: center;
  width: 48.78px;
}
.faq {
  top: 157px;
  width: 26.09px;
}
.faq,
.learnMore,
.newsroom {
  display: flex;
  align-items: center;
}
.newsroom {
  position: absolute;
  top: 195px;
  left: 0;
  line-height: 28px;
  width: 68.23px;
  height: 18px;
}
.learnMore {
  position: relative;
  letter-spacing: 0.6px;
  line-height: 18px;
  text-transform: uppercase;
  width: 81.45px;
  height: 15px;
  flex-shrink: 0;
}
.contactUs,
.freeTrial,
.pricing2,
.requestADemo {
  position: absolute;
  top: 5px;
  left: 0;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 68.65px;
  height: 18px;
}
.freeTrial,
.pricing2,
.requestADemo {
  top: 43px;
  width: 103.26px;
}
.freeTrial,
.pricing2 {
  top: 81px;
  width: 58.79px;
}
.pricing2 {
  top: 119px;
  width: 43.08px;
}
.divelementorWidgetWrap15 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-16xl);
}
.divelementorContainer1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.detailedCate,
.english1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.detailedCate {
  align-self: stretch;
  background-color: var(--color-ghostwhite);
  flex-direction: column;
  padding: var(--padding-12xl) var(--padding-54xl);
}
.english1 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 16px;
  width: 45.79px;
  height: 18px;
}
.spanwpmlLsNative {
  position: relative;
  border-bottom: 2px solid var(--color-gray-400);
  box-sizing: border-box;
  width: 45.59px;
  height: 24px;
  font-size: var(--font-size-sm);
}
.div23,
.espaol {
  position: absolute;
  top: 0;
  left: 2px;
  line-height: 28px;
}
.espaol {
  top: 8.39px;
  left: 19.16px;
  font-size: var(--font-size-sm);
  line-height: 16px;
}
.liwpmlLsSlotFooter {
  position: relative;
  width: 79.34px;
  height: 28.39px;
}
.franais {
  position: absolute;
  top: 8.39px;
  left: 19.15px;
  font-size: var(--font-size-sm);
  line-height: 16px;
}
.liwpmlLsSlotFooter1,
.liwpmlLsSlotFooter2 {
  position: relative;
  width: 82.06px;
  height: 28.39px;
}
.liwpmlLsSlotFooter2 {
  width: 77.73px;
}
.deutsch {
  position: absolute;
  top: 8.39px;
  left: 18.15px;
  font-size: var(--font-size-sm);
  line-height: 16px;
}
.liwpmlLsSlotFooter3,
.liwpmlLsSlotFooter4 {
  position: relative;
  width: 80.92px;
  height: 28.39px;
}
.liwpmlLsSlotFooter4 {
  width: 93.06px;
}
.ul {
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-274xl);
  align-items: center;
  justify-content: center;
  gap: var(--gap-11xs);
}
.language,
.sectionelementorSection1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sectionelementorSection1 {
  border-top: 1px solid var(--color-silver);
  border-bottom: 1px solid var(--color-silver);
  padding: var(--padding-8xl) var(--padding-5xs);
  justify-content: flex-start;
}
.language {
  align-self: stretch;
  background-color: var(--color-ghostwhite);
  padding: 0 var(--padding-59xl);
  justify-content: center;
  text-align: center;
  font-size: var(--font-size-lg);
}
.div28,
.div29,
.div30,
.div31 {
  position: absolute;
  top: 0;
  left: 3px;
  line-height: 26px;
}
.div29,
.div30,
.div31 {
  left: 46.5px;
}
.div30,
.div31 {
  left: 90px;
}
.div31 {
  left: 133.5px;
}
.divelementorWidgetContainer,
.ulelementorIconListItems15 {
  position: absolute;
  top: 0;
  left: -3px;
  width: 174px;
  height: 26px;
}
.divelementorWidgetContainer {
  top: 10px;
  left: 16px;
  border-right: 1px solid var(--color-silver);
  box-sizing: border-box;
  width: 169px;
  overflow: hidden;
}
.div32 {
  position: relative;
  line-height: 26px;
}
.ulelementorIconListItems16 {
  position: absolute;
  top: 0;
  left: -3px;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-mini-5) 0 var(--padding-10xs);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-4xl-5);
}
.divelementorWidgetContainer1 {
  position: absolute;
  top: 10px;
  left: 197px;
  border-right: 1px solid var(--color-silver);
  box-sizing: border-box;
  width: 82px;
  height: 26px;
  overflow: hidden;
}
.blueSeal20042Bbb88495326Icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 142px;
  height: 30px;
  object-fit: cover;
}
.divelementorElement {
  position: absolute;
  top: calc(50% - 15px);
  left: 291px;
  border-right: 1px solid var(--color-silver);
  box-sizing: border-box;
  width: 147.8px;
  height: 30px;
}
.legalCenter {
  position: relative;
  line-height: 20px;
}
.divelementorWidgetContainer2,
.divelementorWidgetContainer3,
.divlegalCenter {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.divelementorWidgetContainer2 {
  overflow: hidden;
}
.divelementorWidgetContainer3,
.divlegalCenter {
  position: absolute;
  font-size: var(--font-size-sm);
  font-family: var(--font-ibm-plex-sans);
}
.divlegalCenter {
  top: calc(50% - 13px);
  left: 450.8px;
  border-right: 1px solid var(--color-silver);
  padding: 0 12.5199966430664var (--padding-7xs) 6px 0;
}
.divelementorWidgetContainer3 {
  top: 10px;
  left: 554.31px;
  overflow: hidden;
}
.divelementorWidgetWrap18 {
  position: relative;
  width: 706.22px;
  height: 46px;
}
.div34 {
  position: absolute;
  top: 15px;
  left: 17px;
  line-height: 18px;
}
.search {
  position: relative;
  font-weight: 500;
}
.divplaceholder1 {
  position: absolute;
  top: 14px;
  left: 43px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 0 151.4199981689453px 0 0;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-sm);
  color: var(--color-lightslategray);
  font-family: var(--font-ibm-plex-sans);
}
.buttonactSearchSubmit,
.divactSearchContainer {
  position: absolute;
  top: 0;
  border-radius: var(--br-9xs-2);
  background-color: var(--color-white);
}
.divactSearchContainer {
  left: 0;
  border: 1px solid var(--color-gray-200);
  box-sizing: border-box;
  width: 270.42px;
  height: 46px;
}
.buttonactSearchSubmit {
  left: 274.42px;
  border: 2px solid var(--color-gray-400);
  display: flex;
  flex-direction: row;
  padding: var(--padding-4xs) 18.55999755859375px var(--padding-4xs)
    var(--padding-mid);
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--font-size-base);
  font-family: var(--font-ibm-plex-sans);
}
.formactSearchForm {
  position: relative;
  border-radius: var(--br-9xs);
  width: 359.98px;
  height: 46px;
  font-size: var(--font-size-lg);
  color: var(--color-gray-400);
  font-family: var(--font-font-awesome-5-free);
}
.divelementorContainer2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.social {
  align-self: stretch;
  background-color: var(--color-ghostwhite);
  padding: var(--padding-13xl) var(--padding-61xl);
  justify-content: center;
  font-size: var(--font-size-7xl);
  color: var(--color-dimgray);
  font-family: var(--font-font-awesome-5-brands);
}
.footer,
.imFucked,
.social {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer {
  align-self: stretch;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--font-size-xs);
  color: var(--color-gray-400);
  font-family: var(--font-ibm-plex-sans);
}
.imFucked {
  position: relative;
  width: 100%;
  overflow: hidden;
  justify-content: space-between;
  text-align: center;
  font-size: var(--font-size-19xl);
  color: var(--color-white);
  font-family: var(--font-poppins);
}
@media screen and (max-width: 1200px) {
  .sectionelementorSection {
    mix-blend-mode: normal;
    background-color: var(--color-orange-100);
    flex: 1;
    flex-direction: column;
    gap: var(--gap-0);
  }
  .top {
    gap: var(--gap-8xs);
  }
  .login {
    display: none;
  }
  .the1ContentContainer {
    line-height: 52px;
  }
}
@media screen and (max-width: 960px) {
  .sectionelementorSection {
    display: none;
    width: auto;
    align-self: unset;
    height: auto;
    flex-direction: column;
  }
  .top {
    display: flex;
    gap: var(--gap-8xs);
  }
  .buttonactButtonWrapper {
    scale: 0.75;
  }
  .vectorStrokeIcon {
    display: flex;
    height: 27px;
    scale: 0.75;
  }
  .empowerYourBusinessContainer {
    font-size: var(--font-size-21xl);
    line-height: 50px;
    width: 591px;
  }
  .groupChild {
    width: 220px;
    height: 45px;
    transform: rotate(0deg);
    border-radius: 0;
    scale: 1.33;
  }
  .frameGroup {
    flex-direction: row;
    gap: 15px;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 10px;
    scale: 0.75;
  }
  .body {
    height: auto;
    gap: var(--gap-0);
    padding-top: 0;
    box-sizing: border-box;
  }
  .groupContainer {
    scale: 0.75;
  }
  .the1ContentIntelligenceParent {
    gap: var(--gap-17xl);
    scale: 0.75;
  }
  .bodyTrending {
    gap: var(--gap-0);
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: border-box;
    height: auto;
  }
  .rectangleGroup {
    scale: 0.75;
  }
  .frameParent1 {
    gap: var(--gap-17xl);
    scale: 0.75;
  }
  .bodyAbtesting {
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: border-box;
  }
  .frameParent2,
  .rectangleContainer {
    scale: 0.75;
  }
  .bodyHottopic,
  .bodySchedule {
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: border-box;
    height: auto;
  }
  .bodySchedule {
    align-items: center;
    justify-content: center;
  }
  .stage {
    scale: 0.75;
  }
  .body1 {
    height: auto;
  }
  .content3Wrapper {
    scale: 0.75;
  }
  .body2 {
    height: auto;
  }
  .teamUpWithYourFavoriteAppParent {
    scale: 0.75;
  }
  .body3 {
    height: auto;
  }
  .customerSpotlightsParent {
    scale: 0.75;
  }
  .testimonial {
    height: auto;
  }
  .frameParent4 {
    scale: 0.75;
  }
  .body4 {
    align-self: stretch;
    width: auto;
    align-items: center;
    justify-content: center;
    max-height: 320px;
    height: auto;
  }
  .divelementorWidgetWrap4 {
    scale: 0.75;
  }
  .testimonial1 {
    align-self: stretch;
    width: auto;
    align-items: center;
    justify-content: center;
    height: auto;
  }
  .divelementorContainer {
    scale: 0.75;
  }
  .touachpoint {
    align-items: center;
    justify-content: center;
    padding-top: var(--padding-3xs);
    padding-bottom: var(--padding-3xs);
    box-sizing: border-box;
    height: auto;
  }
  .divelementorContainer1 {
    scale: 0.75;
  }
  .detailedCate {
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: border-box;
    height: auto;
  }
  .ul {
    scale: 0.75;
  }
  .language {
    height: auto;
  }
  .divelementorContainer2 {
    scale: 0.75;
  }
  .footer,
  .imFucked,
  .social {
    height: auto;
  }
}
@media screen and (max-width: 420px) {
  .sectionelementorSection {
    display: none;
    width: auto;
    align-self: unset;
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-top: 0;
    box-sizing: border-box;
  }
  .top {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .empowerYourBusinessContainer {
    font-size: var(--font-size-xs);
    line-height: 2px;
    text-align: left;
    width: 591px;
  }
}
@media screen and (max-width: 1188px) {
  .buttonactButtonWrapper {
    display: none;
  }
}
@media screen and (max-width: 1289px) {
  .buttonactButtonWrapper {
    display: none;
  }
}
@media screen and (max-width: 1493px) {
  .buttonactButtonWrapper {
    display: flex;
  }
}
@media screen and (max-width: 1148px) {
  .vectorStrokeIcon {
    display: none;
  }
}
@media screen and (max-width: 770px) {
  .buttonactButtonWrapper {
    display: none;
  }
}
@media screen and (max-width: 1410px) {
  .login {
    display: none;
  }
}
@media screen and (max-width: 1182px) {
  .catgoryWrapper {
    display: none;
  }
  .vectorStrokeIcon {
    display: flex;
  }
}

.customerSpotlights {
  position: absolute;
  bottom: 567.49px;
  left: calc(50% - 206.78px);
  font-size: var(--font-size-21xl);
  line-height: 54px;
  display: flex;
  font-family: var(--font-poppins);
  align-items: center;
  justify-content: center;
  width: 421.76px;
  height: 56px;
}
.child,
.msi768x589pngIcon {
  position: absolute;
  width: 251px;
}
.child {
  top: 0.05px;
  left: 0;
  background-color: var(--color-white);
  height: 444px;
}
.msi768x589pngIcon {
  top: calc(50% - 214.25px);
  left: calc(50% - 125.5px);
  height: 192.48px;
  object-fit: cover;
}
.museumOfScience {
  margin: 0;
}
.museumOfScienceContainer1 {
  line-break: anywhere;
  width: 100%;
}
.activecampaignLetsUsContainer,
.museumOfScienceContainer {
  position: absolute;
  top: 210.49px;
  left: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 144.54px;
  height: 44px;
}
.activecampaignLetsUsContainer {
  top: 274.49px;
  width: 206.15px;
  height: 92px;
}
.div2,
.seeMore {
  position: absolute;
  color: var(--color-mediumblue);
}
.seeMore {
  top: 404.49px;
  left: 21px;
  line-height: 24px;
  font-weight: 500;
}
.div2 {
  top: 410.49px;
  left: 94.22px;
  font-size: var(--font-size-sm);
  line-height: 14px;
  font-family: var(--font-font-awesome-5-free);
  text-align: right;
}
.div,
.div1 {
  position: absolute;
  width: 251px;
}
.div1 {
  top: calc(50% - 222.02px);
  left: calc(50% - 125.5px);
  height: 428.49px;
}
.div {
  top: 103px;
  left: 259px;
  height: 444.05px;
  text-align: left;
  color: var(--color-darkslategray-100);
}
.seeAllCustomer {
  top: 0;
  left: 0;
  line-height: 24px;
  font-weight: 500;
}
.div3,
.seeAllCustomer,
.seemore {
  position: absolute;
}
.div3 {
  top: 6px;
  left: 220.61px;
  font-size: var(--font-size-sm);
  line-height: 14px;
  font-family: var(--font-font-awesome-5-free);
  text-align: right;
}
.seemore {
  top: 599.49px;
  left: 401.81px;
  width: 229.61px;
  height: 24px;
}
.customerSpotlightsParent {
  position: relative;
  width: 1028px;
  height: 623.49px;
  z-index: 0;
}
.testimonial {
  align-self: stretch;
  background-color: var(--color-whitesmoke-100);
  height: 721px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--color-gray-600);
  font-family: var(--font-ibm-plex-sans);
}

.startForFree {
  text-decoration: underline;
}
.startForFreeToday {
  color: inherit;
}
.heyThereFreeContainer {
  position: relative;
  line-height: 17.5px;
}
.sectionelementorSection,
.top {
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.01);
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.sectionelementorSection {
  flex: 1;
  background-color: var(--color-orange);
  height: 40px;
  align-items: center;
}
.top {
  align-self: stretch;
  background-color: var(--color-gray-700);
  height: 44px;
  align-items: flex-start;
  text-align: center;
  color: inherit;
}
.contentiifyIcon {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 561.5px);
  width: 136px;
  height: 24px;
}
.features,
.icon {
  position: relative;
}
.features {
  line-height: 14px;
}
.icon {
  width: 8.13px;
  height: 4.62px;
  mix-blend-mode: normal;
}
.platform,
.products {
  height: 72px;
  box-sizing: border-box;
}
.products {
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.01);
  width: 97.05px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-10xl) var(--padding-mini);
  align-items: center;
  justify-content: center;
  gap: var(--gap-6xs);
}
.platform {
  width: 95.75px;
  gap: var(--gap-8xs);
}
.platform,
.pricing {
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.01);
  display: flex;
  flex-direction: row;
  padding: var(--padding-10xl) var(--padding-xs);
  align-items: center;
  justify-content: center;
}
.catgory,
.catgoryWrapper {
  position: absolute;
  top: calc(50% - 36px);
  height: 72px;
}
.catgory {
  left: calc(50% - 325px);
  width: 597px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xl);
}
.catgoryWrapper {
  left: calc(50% - 391.5px);
  width: 582px;
}
.vectorIcon {
  width: 20px;
  height: 20px;
}
.english,
.icon2,
.vectorIcon {
  position: relative;
}
.english {
  line-height: 20px;
  font-weight: 500;
}
.icon2 {
  width: 8.13px;
  height: 4.62px;
}
.vectorParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.login1 {
  position: relative;
  line-height: 28px;
  font-weight: 500;
  text-align: left;
}
.login {
  position: absolute;
  height: calc(100% - 44px);
  top: 22px;
  right: 234px;
  bottom: 22px;
  width: 227px;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-4xl) 0 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-xl);
  text-align: center;
}
.contentiifyParent,
.frameWrapper {
  position: absolute;
  top: calc(50% - 36px);
  height: 72px;
}
.contentiifyParent {
  left: calc(50% - 695px);
  width: 1197px;
}
.frameWrapper {
  left: 0;
  width: 1390px;
}
.buttonactButton {
  position: absolute;
  top: 0;
  right: 0;
}
.buttonactButtonWrapper {
  position: absolute;
  height: calc(100% - 32px);
  top: 16.23px;
  right: 88px;
  bottom: 15.77px;
  width: 129px;
}
.vectorStrokeIcon {
  position: absolute;
  height: calc(100% - 51px);
  top: 22.5px;
  right: 34px;
  bottom: 28.5px;
  max-height: 100%;
  width: 21px;
  display: none;
}
.frameParent {
  position: relative;
  width: 1200px;
  height: 72px;
}
.contentifyDraft20230521Inner {
  align-self: stretch;
  background-color: var(--color-darkslategray-200);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  color: var(--color-white);
}
.empowerYourBusiness {
  margin: 0;
}
.empowerYourBusinessContainer1 {
  line-break: anywhere;
  width: 100%;
}
.empowerYourBusinessContainer {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 81px;
  display: flex;
  align-items: center;
  height: 184.89px;
}
.frameChild {
  position: absolute;
  height: calc(100% - 110.89px);
  top: 98.5px;
  bottom: 12.39px;
  left: calc(50% - 192.24px);
  max-height: 100%;
  width: 330.08px;
}
.empowerYourBusinessWithVirParent {
  position: relative;
  width: 591px;
  height: 184.89px;
  font-family: var(--font-poppins);
}
.theSocialMedia {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 32px;
  display: flex;
  align-items: center;
}
.theSocialMediaContentMarkWrapper {
  position: relative;
  width: 557.5px;
  height: 64px;
  font-size: var(--font-size-lg);
}
.getViral {
  position: relative;
  line-height: 32px;
  font-weight: 500;
}
.buttonactButton1,
.tryItFree {
  display: flex;
  align-items: center;
}
.buttonactButton1 {
  border-radius: var(--br-8xs);
  background-color: var(--color-mediumturquoise);
  height: 48px;
  flex-direction: row;
  padding: var(--padding-5xs) 44.51000213623047px var(--padding-5xs)
    var(--padding-26xl);
  box-sizing: border-box;
  justify-content: center;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--color-darkslategray-200);
}
.tryItFree {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 17.5px;
}
.tryItFreeNoCreditCardReWrapper {
  position: relative;
  width: 557.5px;
  height: 18px;
  font-size: var(--font-size-sm);
}
.frameContainer {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 var(--padding-31xl);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xl);
}
.imageCrop1Icon {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  height: auto;
  object-fit: cover;
}
.imageCrop1Wrapper {
  position: relative;
  width: 613.5px;
  height: 744px;
  margin-left: -31px;
}
.frameGroup {
  height: 620px;
  display: flex;
  flex-direction: row;
  padding: 0 0 0 var(--padding-10xl);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
  z-index: 1;
}
.body,
.bodyChild {
  align-self: stretch;
}
.bodyChild {
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 265px;
  flex-shrink: 0;
  object-fit: cover;
  z-index: 0;
  margin-top: -344px;
}
.body {
  background-color: var(--color-darkslategray-200);
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.01);
  height: 681px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-81xl) 0 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-end;
  mix-blend-mode: normal;
  font-size: var(--font-size-29xl);
}
.forSeamlessMarketing {
  font-size: var(--font-size-19xl);
  color: var(--color-gray-300);
}
.aiPoweredForContainer {
  position: relative;
  line-height: 45px;
  display: flex;
  align-items: center;
  width: 621px;
}
.pseudo {
  position: absolute;
  top: -11px;
  left: 1px;
  background-color: var(--color-mediumblue);
  width: 248px;
  height: 11px;
}
.reach,
.targetToThose {
  display: flex;
  align-items: center;
}
.reach {
  position: absolute;
  top: 14px;
  left: 17px;
  line-height: 16px;
  width: 46.65px;
  height: 20px;
}
.targetToThose {
  position: relative;
  line-height: 20px;
  width: 208px;
  flex-shrink: 0;
}
.hashtagAnalytics,
.span {
  position: absolute;
  display: flex;
}
.span {
  top: 48px;
  left: 17px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-sm);
}
.hashtagAnalytics {
  top: 57px;
  left: 1px;
  line-height: 30px;
  font-weight: 500;
  align-items: center;
  width: 208px;
}
.audienceDemographicsAnalytic,
.socialIntelligenceFacebook {
  position: absolute;
  top: 98px;
  left: 0;
  line-height: 30px;
  font-weight: 500;
}
.socialIntelligenceFacebook {
  top: 0;
  line-height: 23px;
  display: flex;
  align-items: center;
  width: 208px;
}
.timeSeriesAnalytics {
  position: absolute;
  top: 139px;
  left: 0;
  line-height: 30px;
  font-weight: 500;
}
.ulelementorIconListItems {
  position: absolute;
  top: 140.93px;
  left: 17px;
  width: 216px;
  height: 138px;
  font-size: var(--font-size-sm-6);
  color: var(--color-gray-600);
}
.divelementorWidgetWrap {
  position: relative;
  background-color: var(--color-white);
  border-right: 1px solid var(--color-lavender);
  border-bottom: 1px solid var(--color-lavender);
  border-left: 1px solid var(--color-lavender);
  box-sizing: border-box;
  width: 250px;
  height: 327px;
}
.pseudo1 {
  position: absolute;
  top: -11px;
  left: 1px;
  background-color: var(--color-mediumturquoise);
  width: 248px;
  height: 11px;
}
.growYourRelationshipContainer,
.nurture {
  position: absolute;
  top: 14px;
  left: 17px;
  line-height: 16px;
  display: flex;
  align-items: center;
  width: 58.9px;
  height: 20px;
}
.growYourRelationshipContainer {
  top: 1px;
  left: 0;
  line-height: 20px;
  width: 173.04px;
  height: 58px;
}
.p {
  position: absolute;
  top: 48px;
  left: 17px;
  width: 184px;
  height: 60px;
  font-size: var(--font-size-sm);
}
.aiSupportedContents,
.suggestedMessaging {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 30px;
  font-weight: 500;
}
.suggestedMessaging {
  top: 36.93px;
}
.contentScheduling,
.performanceMonitoring {
  position: absolute;
  top: 73.93px;
  left: 0;
  line-height: 23px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 208px;
}
.contentScheduling {
  top: 126.93px;
  width: 207px;
}
.ulelementorIconListItems1 {
  position: absolute;
  top: 133px;
  left: 17px;
  width: 216px;
  height: 174px;
  font-size: var(--font-size-sm-6);
  color: var(--color-gray-600);
}
.convert {
  position: absolute;
  top: 14px;
  left: 17px;
  line-height: 16px;
  display: flex;
  align-items: center;
  width: 59.18px;
  height: 20px;
}
.attributionAnalytics,
.provideIndividualAudienceContainer {
  position: absolute;
  top: 1px;
  left: 0;
  line-height: 20px;
  display: flex;
  align-items: center;
  width: 172.29px;
  height: 58px;
}
.attributionAnalytics {
  top: 42px;
  line-height: 30px;
  font-weight: 500;
  width: 161.47px;
  height: 18px;
}
.ulelementorIconListItems2 {
  position: absolute;
  top: 141.93px;
  left: 17px;
  width: 216px;
  height: 67px;
  font-size: var(--font-size-sm-6);
  color: var(--color-gray-600);
}
.grow,
.pseudo3 {
  position: absolute;
}
.pseudo3 {
  top: -11px;
  left: 1px;
  background-color: var(--color-mediumaquamarine);
  width: 248px;
  height: 11px;
}
.grow {
  top: 14px;
  left: 17px;
  line-height: 16px;
  display: flex;
  align-items: center;
  width: 40.42px;
  height: 20px;
}
.delightEachCustomerContainer {
  position: absolute;
  top: 0.93px;
  left: 0;
  line-height: 20px;
  display: inline-block;
  width: 201px;
  height: 58px;
}
.aiSeeding,
.leadScoring {
  position: absolute;
  left: 0;
  line-height: 30px;
  font-weight: 500;
}
.aiSeeding {
  top: 6px;
  display: flex;
  align-items: center;
  width: 88.86px;
  height: 18px;
}
.leadScoring {
  top: 36px;
}
.ulelementorIconListItems3 {
  position: absolute;
  top: 136px;
  left: 17px;
  width: 216px;
  height: 174px;
  font-size: var(--font-size-sm-6);
  color: var(--color-gray-600);
}
.divelementorWidgetWrapParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-gray-400);
  font-family: var(--font-ibm-plex-sans);
}
.aiPoweredForSeamlessMarketParent,
.stage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.aiPoweredForSeamlessMarketParent {
  height: 454px;
  gap: var(--gap-17xl);
}
.stage {
  position: absolute;
  top: calc(50% - 227px);
  left: calc(50% - 529.5px);
}
.body1 {
  align-self: stretch;
  position: relative;
  background-color: var(--color-whitesmoke-200);
  height: 602px;
  text-align: center;
  font-size: var(--font-size-21xl);
  font-family: var(--font-poppins);
}
.body1,
.contentIntelligence {
  color: var(--color-mediumturquoise);
}
.orPlatform {
  color: var(--color-white);
}
.the1ContentContainer {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  line-height: 52px;
  display: flex;
  align-items: center;
}
.the1ContentIntelligenceWrapper {
  position: relative;
  width: 689px;
  height: 104px;
}
.contentify251 {
  position: absolute;
  top: 0;
  left: calc(50% - 479px);
  width: 958px;
  height: 349px;
  object-fit: cover;
}
.groupChild,
.spy100cContents {
  position: absolute;
  transform-origin: 0 0;
}
.groupChild {
  top: 24.83px;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  border-top: 1px solid var(--color-mediumvioletred);
  border-right: 1px solid var(--color-mediumvioletred);
  border-bottom: 5px solid var(--color-mediumvioletred);
  border-left: 1px solid var(--color-mediumvioletred);
  box-sizing: border-box;
  width: 207px;
  height: 59.27px;
  transform: rotate(-6.89deg);
}
.spy100cContents {
  top: 40px;
  left: 12.66px;
  transform: rotate(-6.53deg);
}
.groupWrapper,
.rectangleParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 212.61px;
  height: 83.67px;
}
.groupWrapper {
  top: 215.58px;
  left: 649px;
}
.contentify251Parent {
  position: relative;
  width: 958px;
  height: 349px;
  font-size: var(--font-size-xl);
  color: var(--color-mediumvioletred);
  font-family: var(--font-roboto);
}
.frameDiv {
  position: absolute;
  top: calc(50% - 244.5px);
  left: calc(50% - 478.5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-17xl);
}
.body2 {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-29xl) var(--br-29xl) 0 0;
  background-color: var(--color-darkslategray-200);
  height: 643px;
  text-align: center;
  font-size: var(--font-size-19xl);
  color: var(--color-whitesmoke-500);
  font-family: var(--font-poppins);
}
.span1 {
  color: var(--color-mediumvioletred);
}
.spyYourCompetitorContainer {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 52px;
  display: flex;
  align-items: center;
}
.spyYourCompetitorSAudiencWrapper {
  position: relative;
  width: 662px;
  height: 104px;
}
.contentify11 {
  position: absolute;
  top: 0;
  left: 0;
  width: 956px;
  height: 327px;
  object-fit: cover;
}
.groupItem {
  position: absolute;
  top: 37.01px;
  left: 19.24px;
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  border-top: 1px solid var(--color-mediumaquamarine-100);
  border-right: 1px solid var(--color-mediumaquamarine-100);
  border-bottom: 5px solid var(--color-mediumaquamarine-100);
  border-left: 1px solid var(--color-mediumaquamarine-100);
  box-sizing: border-box;
  width: 242.98px;
  height: 81.54px;
  transform: rotate(-5.56deg);
  transform-origin: 0 0;
}
.audienceAnalyticsForContainer {
  position: absolute;
  top: 45.06px;
  left: -2.33px;
  display: flex;
  align-items: center;
  width: 281.99px;
  height: 63.28px;
  transform: rotate(-5.31deg);
  transform-origin: 0 0;
}
.rectangleGroup {
  position: absolute;
  top: 135px;
  left: 209.01px;
  width: 288.16px;
  height: 116.61px;
  transform: rotate(2.96deg);
  transform-origin: 0 0;
}
.contentify11Parent {
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 2px;
  width: 956px;
  height: 327px;
  z-index: 0;
}
.frameParent1,
.groupContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.groupContainer {
  width: 960px;
  height: 333px;
  position: relative;
  font-size: var(--font-size-xl);
  color: var(--color-mediumaquamarine);
  font-family: var(--font-roboto);
}
.frameParent1 {
  position: absolute;
  top: calc(50% - 237px);
  left: calc(50% - 479.5px);
  gap: var(--gap-17xl);
}
.body3 {
  align-self: stretch;
  position: relative;
  background-color: var(--color-darkslategray-200);
  height: 610px;
  text-align: center;
  font-size: var(--font-size-19xl);
  color: var(--color-mediumturquoise);
  font-family: var(--font-poppins);
}
.span2 {
  color: var(--color-mediumblue);
}
.tailorContentsBasedContainer {
  position: absolute;
  top: 0.36px;
  left: calc(50% - 296px);
  line-height: 52px;
  display: flex;
  align-items: center;
  width: 591px;
}
.contentify22,
.tailorContentsBasedOnHotTWrapper {
  position: relative;
  width: 722px;
  height: 104px;
}
.contentify22 {
  border-radius: var(--br-4xs);
  width: 962px;
  height: 337px;
  object-fit: cover;
  z-index: 0;
}
.autoGenerateVideos,
.groupInner {
  position: absolute;
  transform-origin: 0 0;
}
.groupInner {
  top: 30.89px;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  border-top: 1px solid var(--color-mediumvioletred);
  border-right: 1px solid var(--color-mediumvioletred);
  border-bottom: 5px solid var(--color-mediumvioletred);
  border-left: 1px solid var(--color-mediumvioletred);
  box-sizing: border-box;
  width: 257.5px;
  height: 73.73px;
  transform: rotate(-6.89deg);
}
.autoGenerateVideos {
  top: 41.89px;
  left: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 239.21px;
  transform: rotate(-6.53deg);
}
.groupFrame,
.rectangleContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 264.49px;
  height: 104.08px;
}
.groupFrame {
  margin: 0 !important;
  top: 191px;
  left: 217px;
  z-index: 1;
}
.contentify22Parent,
.frameParent2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.contentify22Parent {
  position: relative;
  gap: var(--gap-7xl);
  font-size: var(--font-size-xl);
  color: var(--color-mediumvioletred);
  font-family: var(--font-roboto);
}
.frameParent2 {
  position: absolute;
  top: calc(50% - 239px);
  left: calc(50% - 480.5px);
  gap: var(--gap-17xl);
}
.body4 {
  align-self: stretch;
  position: relative;
  background-color: var(--color-darkslategray-200);
  height: 620px;
  text-align: center;
  font-size: var(--font-size-19xl);
  color: var(--color-white);
  font-family: var(--font-poppins);
}
.scheduleYourContentsContainer {
  position: absolute;
  top: 26.36px;
  left: calc(50% - 299px);
  line-height: 52px;
}
.contentify42 {
  position: relative;
  width: 956px;
  height: 333px;
  object-fit: cover;
  z-index: 0;
}
.rectangleDiv {
  top: 31.35px;
  left: -1.62px;
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  border-top: 1px solid var(--color-mediumaquamarine-100);
  border-right: 1px solid var(--color-mediumaquamarine-100);
  border-bottom: 5px solid var(--color-mediumaquamarine-100);
  border-left: 1px solid var(--color-mediumaquamarine-100);
  box-sizing: border-box;
  width: 219.44px;
  height: 81.6px;
  transform: rotate(-5.27deg);
}
.getThingsPlanned,
.groupDiv,
.rectangleDiv {
  position: absolute;
  transform-origin: 0 0;
}
.getThingsPlanned {
  top: 36.15px;
  left: 13.35px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 184.98px;
  height: 63.33px;
  transform: rotate(-5.03deg);
}
.groupDiv {
  margin: 0 !important;
  top: 191px;
  left: 515.77px;
  width: 229.68px;
  height: 112.03px;
  transform: rotate(2.96deg);
  z-index: 1;
}
.contentify42Parent,
.frameParent3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.contentify42Parent {
  position: relative;
  gap: var(--gap-7xl);
  font-size: var(--font-size-xl);
  color: var(--color-mediumaquamarine);
  font-family: var(--font-roboto);
}
.frameParent3 {
  position: absolute;
  top: calc(50% - 236.5px);
  left: calc(50% - 477.5px);
  gap: var(--gap-17xl);
}
.body5 {
  align-self: stretch;
  position: relative;
  background-color: var(--color-darkslategray-200);
  height: 621px;
  text-align: center;
  font-size: var(--font-size-19xl);
  color: var(--color-mediumturquoise);
  font-family: var(--font-poppins);
}
.bodyItem {
  position: absolute;
  margin: 0 !important;
  height: calc(100% - 1.47px);
  top: 0.65px;
  bottom: 0.82px;
  left: calc(50% - 959.5px);
  max-height: 100%;
  width: 1920px;
  display: none;
  z-index: 0;
}
.emailmarketingpngIcon {
  position: absolute;
  top: 0;
  left: calc(50% + 34px);
  width: 496px;
  height: 524.47px;
  object-fit: cover;
}
.social {
  font-size: var(--font-size-sm);
  letter-spacing: 0.6px;
  line-height: 28px;
  text-transform: uppercase;
  width: 255.54px;
  height: 18px;
}
.craftEmailNewslettersContainer,
.sendExactlyWhatContainer,
.social {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.sendExactlyWhatContainer {
  font-size: var(--font-size-17xl);
  line-height: 48.6px;
  font-family: var(--font-poppins);
  width: 433.54px;
  height: 99.6px;
}
.craftEmailNewslettersContainer {
  font-size: var(--font-size-lg);
  line-height: 28px;
  width: 517.31px;
  height: 79px;
}
.spanelementorDividerSeparat {
  position: relative;
  border-top: 2px solid var(--color-gray-100);
  box-sizing: border-box;
  width: 30px;
  height: 2px;
}
.contactListSizesContainer,
.emailMarketing {
  line-height: 24px;
  display: flex;
  align-items: center;
}
.contactListSizesContainer {
  position: relative;
  font-weight: 600;
  width: 376.56px;
  height: 44px;
  flex-shrink: 0;
}
.emailMarketing {
  position: absolute;
  top: 0;
  left: 22.16px;
  width: 116.65px;
  height: 20px;
}
.vectorIcon1 {
  position: absolute;
  height: 20.46%;
  width: 3.48%;
  top: 14.96%;
  right: 96.52%;
  bottom: 64.58%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.dynamicContent {
  position: absolute;
  top: 29px;
  left: 22.16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 121.39px;
  height: 20px;
}
.vectorIcon2 {
  position: absolute;
  height: 20.46%;
  width: 3.48%;
  top: 70.72%;
  right: 96.52%;
  bottom: 8.82%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.segmentation {
  position: absolute;
  top: 0;
  left: 209.35px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 100.4px;
  height: 20px;
}
.vectorIcon3 {
  position: absolute;
  height: 20.46%;
  width: 3.48%;
  top: 14.96%;
  right: 47.64%;
  bottom: 64.58%;
  left: 48.88%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.successfulDeliverability {
  position: absolute;
  top: 29px;
  left: 209.35px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 173.62px;
  height: 20px;
}
.vectorIcon4 {
  position: absolute;
  height: 20.46%;
  width: 3.48%;
  top: 70.72%;
  right: 47.64%;
  bottom: 8.82%;
  left: 48.88%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.emailMarketingParent {
  position: relative;
  width: 382.97px;
  height: 49px;
}
.personalizeYourEmail {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.aelementorButtonLink {
  border-radius: var(--br-10xs);
  border: 2px solid var(--color-gray-100);
  box-sizing: border-box;
  width: 221px;
  height: 52px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-sm) 0;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--color-mediumturquoise);
}
.socialParent {
  position: absolute;
  top: 13.27px;
  left: calc(50% - 530px);
  width: 517.31px;
  height: 498.52px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-4xl);
}
.body6,
.content {
  position: relative;
}
.content {
  width: 1060px;
  height: 524.47px;
  z-index: 1;
}
.body6 {
  align-self: stretch;
  border-radius: var(--br-29xl) var(--br-29xl) 0 0;
  background-color: var(--color-darkslategray-200);
  height: 686.47px;
  display: none;
  flex-direction: row;
  padding: var(--padding-84xl) 0 var(--padding-40xl) var(--padding-11xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
}
.marketingautomationpngIcon {
  position: absolute;
  top: calc(50% - 285.53px);
  left: calc(50% - 530px);
  width: 496px;
  height: 571.06px;
  object-fit: cover;
}
.powerfulMarketingAutomation {
  position: absolute;
  top: 36.44px;
  left: 540px;
  font-size: var(--font-size-sm);
  letter-spacing: 0.6px;
  line-height: 28px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  width: 274.29px;
  height: 18px;
}
.automationGivesTimeContainer {
  position: absolute;
  top: -2px;
  left: 0;
  line-height: 48.6px;
  display: flex;
  align-items: center;
  width: 424.22px;
  height: 99.59px;
}
.h3elementorHeadingTitle {
  position: absolute;
  top: 59.44px;
  left: 540px;
  width: 520px;
  height: 97.19px;
  font-size: var(--font-size-17xl);
  font-family: var(--font-poppins);
}
.makeItEasyContainer {
  position: absolute;
  top: 2px;
  left: 0;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 508.67px;
  height: 79px;
}
.p3 {
  position: absolute;
  top: 176.63px;
  left: 540px;
  width: 520px;
  height: 84px;
  font-size: var(--font-size-lg);
}
.spanelementorDividerSeparat1 {
  position: absolute;
  top: 300.63px;
  left: 540px;
  border-top: 2px solid var(--color-mediumturquoise);
  box-sizing: border-box;
  width: 30px;
  height: 2px;
}
.automationHelps95Container {
  position: absolute;
  top: 2px;
  left: 0;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 376.39px;
  height: 44px;
}
.p4 {
  position: absolute;
  top: 326.63px;
  left: 540px;
  width: 408px;
  height: 48px;
}
.contactManagement {
  position: absolute;
  top: 2px;
  left: 22.5px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 154.53px;
  height: 20px;
}
.svgIcon {
  position: absolute;
  top: 5px;
  left: 0;
  width: 14px;
  height: 14px;
  overflow: hidden;
}
.lielementorIconListItem {
  position: absolute;
  top: 0;
  left: 0;
  width: 208px;
  height: 26.5px;
}
.advancedReporting {
  position: absolute;
  top: 2px;
  left: 22.5px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 140.79px;
  height: 20px;
}
.lielementorIconListItem1,
.ulelementorIconListItems4 {
  position: absolute;
  top: 29px;
  left: 0;
  width: 208px;
  height: 24px;
}
.ulelementorIconListItems4 {
  top: 0;
  height: 53px;
}
.automatedLeadNurturing {
  position: absolute;
  top: 2px;
  left: 22.5px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 184.93px;
  height: 20px;
}
.dynamicContent1,
.lielementorIconListItem2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 310.06px;
  height: 26.5px;
}
.dynamicContent1 {
  top: 2px;
  left: 22.5px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 121.39px;
  height: 20px;
}
.lielementorIconListItem3,
.ulelementorIconListItems5 {
  position: absolute;
  top: 29px;
  left: 0;
  width: 310.06px;
  height: 24px;
}
.ulelementorIconListItems5 {
  top: 0;
  left: 208px;
  height: 53px;
}
.divelementorContainer {
  position: absolute;
  top: 410.63px;
  left: calc(50% + 10px);
  width: 520px;
  height: 53px;
}
.aelementorButtonLink1 {
  position: absolute;
  top: 487.63px;
  left: 540px;
  border-radius: var(--br-10xs);
  border: 2px solid var(--color-mediumturquoise);
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm) 26.139999389648438px var(--padding-sm)
    var(--padding-6xl);
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  color: var(--color-mediumturquoise);
}
.marketingautomationpngParent {
  position: absolute;
  top: calc(50% - 285.25px);
  left: calc(50% - 529.5px);
  width: 1060px;
  height: 571.06px;
}
.body7 {
  align-self: stretch;
  position: relative;
  background-color: var(--color-darkslategray-200);
  height: 717px;
  display: none;
}
.crm {
  top: 5px;
  font-size: var(--font-size-sm);
  letter-spacing: 0.6px;
  line-height: 28px;
  text-transform: uppercase;
  width: 32.03px;
  height: 18px;
}
.aCrmWithContainer,
.crm,
.salesAutomationHelpsContainer {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
}
.salesAutomationHelpsContainer {
  top: 26px;
  font-size: var(--font-size-17xl);
  line-height: 48.6px;
  font-family: var(--font-poppins);
  width: 515.37px;
  height: 99.6px;
}
.aCrmWithContainer {
  top: 147.19px;
  font-size: var(--font-size-lg);
  line-height: 28px;
  width: 515.53px;
  height: 79px;
}
.spanelementorDividerSeparat2 {
  position: absolute;
  top: 269.19px;
  left: 0;
  border-top: 2px solid var(--color-mediumturquoise);
  box-sizing: border-box;
  width: 30px;
  height: 2px;
}
.crm1,
.salesAutomationIncreasesContainer {
  position: absolute;
  line-height: 24px;
  display: flex;
  align-items: center;
}
.salesAutomationIncreasesContainer {
  top: 297.19px;
  left: 0;
  font-weight: 600;
  width: 387.72px;
  height: 44px;
}
.crm1 {
  top: 381.19px;
  left: 22.5px;
  width: 33.37px;
  height: 20px;
}
.vectorIcon5 {
  position: absolute;
  height: 2.09%;
  width: 1.26%;
  top: 75.9%;
  right: 98.71%;
  bottom: 22.01%;
  left: 0.03%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.winProbability {
  position: absolute;
  top: 410.19px;
  left: 22.5px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 108.42px;
  height: 20px;
}
.vectorIcon6 {
  position: absolute;
  height: 2.09%;
  width: 1.26%;
  top: 81.6%;
  right: 98.71%;
  bottom: 16.3%;
  left: 0.03%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.leadMagnets {
  position: absolute;
  top: 381.19px;
  left: 209.69px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 161.06px;
  height: 20px;
}
.vectorIcon7 {
  position: absolute;
  height: 2.09%;
  width: 1.26%;
  top: 75.9%;
  right: 81.05%;
  bottom: 22.01%;
  left: 17.69%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.salesEngagement {
  position: absolute;
  top: 410.19px;
  left: 209.69px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 132.15px;
  height: 20px;
}
.vectorIcon8 {
  position: absolute;
  height: 2.09%;
  width: 1.26%;
  top: 81.6%;
  right: 81.05%;
  bottom: 16.3%;
  left: 17.69%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.aelementorButtonLink2 {
  position: absolute;
  top: 456.19px;
  left: 0;
  border-radius: var(--br-10xs);
  border: 2px solid var(--color-mediumturquoise);
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm) 25.139999389648438px var(--padding-sm)
    var(--padding-7xl);
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  color: var(--color-mediumturquoise);
}
.crmpngIcon {
  position: absolute;
  top: calc(50% - 254.09px);
  left: calc(50% + 34px);
  width: 496px;
  height: 403.81px;
  object-fit: cover;
}
.crmParent {
  position: absolute;
  top: calc(50% - 230.36px);
  left: calc(50% - 529.5px);
  width: 1060px;
  height: 508.19px;
}
.body8 {
  align-self: stretch;
  position: relative;
  background: linear-gradient(#0c2b36, #0c2b36), #fdfdfd;
  height: 689.35px;
  display: none;
}
.websitemessagingpngIcon {
  position: absolute;
  top: calc(50% - 258.09px);
  left: calc(50% - 529.22px);
  width: 496px;
  height: 472.67px;
  object-fit: cover;
}
.messageYourCustomersContainer,
.messagingLive {
  position: absolute;
  left: 540px;
  display: flex;
  align-items: center;
}
.messagingLive {
  top: 5px;
  font-size: var(--font-size-sm);
  letter-spacing: 0.6px;
  line-height: 28px;
  text-transform: uppercase;
  width: 182.72px;
  height: 18px;
}
.messageYourCustomersContainer {
  top: 26px;
  font-size: var(--font-size-17xl);
  line-height: 48.6px;
  font-family: var(--font-poppins);
  width: 466.76px;
  height: 99.59px;
}
.makeMeaningfulConnectionsContainer {
  position: absolute;
  top: 147.19px;
  left: 540px;
  font-size: var(--font-size-lg);
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 518.45px;
  height: 79px;
}
.spanelementorDividerSeparat3 {
  position: absolute;
  top: 269.19px;
  left: 540px;
  border-top: 2px solid var(--color-mediumturquoise);
  box-sizing: border-box;
  width: 30px;
  height: 2px;
}
.ofBusinessesGrowContainer,
.siteMessaging {
  position: absolute;
  line-height: 24px;
  display: flex;
  align-items: center;
}
.ofBusinessesGrowContainer {
  top: 297.19px;
  left: 540px;
  font-weight: 600;
  width: 400.11px;
  height: 68px;
}
.siteMessaging {
  top: 389.19px;
  left: 562.5px;
  width: 108.39px;
  height: 20px;
}
.vectorIcon9 {
  position: absolute;
  height: 2.06%;
  width: 1.26%;
  top: 76.27%;
  right: 47.69%;
  bottom: 21.67%;
  left: 51.05%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.engagementTracking {
  position: absolute;
  top: 418.19px;
  left: 562.5px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 151.92px;
  height: 20px;
}
.vectorIcon10 {
  position: absolute;
  height: 2.06%;
  width: 1.26%;
  top: 81.89%;
  right: 47.69%;
  bottom: 16.05%;
  left: 51.05%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.sms {
  position: absolute;
  top: 389.19px;
  left: 770.5px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 31.79px;
  height: 20px;
}
.vectorIcon11 {
  position: absolute;
  height: 2.06%;
  width: 1.26%;
  top: 76.27%;
  right: 28.04%;
  bottom: 21.67%;
  left: 70.7%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.segmentation1 {
  position: absolute;
  top: 418.19px;
  left: 770.5px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 100.4px;
  height: 20px;
}
.vectorIcon12 {
  position: absolute;
  height: 2.06%;
  width: 1.26%;
  top: 81.89%;
  right: 28.04%;
  bottom: 16.05%;
  left: 70.7%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.aelementorButtonLink3 {
  position: absolute;
  top: 464.19px;
  left: 540px;
  border-radius: var(--br-10xs);
  border: 2px solid var(--color-mediumturquoise);
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm) 25.919998168945312px var(--padding-sm)
    var(--padding-7xl);
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  color: var(--color-mediumturquoise);
}
.websitemessagingpngParent {
  position: absolute;
  top: calc(50% - 245.18px);
  left: calc(50% - 529.5px);
  width: 1058.45px;
  height: 516.19px;
}
.body9 {
  align-self: stretch;
  position: relative;
  background: linear-gradient(#0c2b36, #0c2b36), #0c2b36;
  height: 689.35px;
  display: none;
}
.anAutomationFirstApproachContainer,
.focusOnQualified {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
}
.focusOnQualified {
  top: 5px;
  font-size: var(--font-size-sm);
  letter-spacing: 0.6px;
  line-height: 28px;
  text-transform: uppercase;
  width: 208.9px;
  height: 18px;
}
.anAutomationFirstApproachContainer {
  top: 26px;
  font-size: var(--font-size-17xl);
  line-height: 48.6px;
  font-family: var(--font-poppins);
  width: 374.47px;
  height: 148.19px;
}
.salesEngagementAutomationContainer {
  position: absolute;
  top: 195.78px;
  left: 0;
  font-size: var(--font-size-lg);
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 518.03px;
  height: 135px;
}
.spanelementorDividerSeparat4 {
  position: absolute;
  top: 373.78px;
  left: 0;
  border-top: 2px solid var(--color-mediumturquoise);
  box-sizing: border-box;
  width: 30px;
  height: 2px;
}
.automatedOneToOneEmail,
.salesEngagementAutomationContainer2 {
  position: absolute;
  line-height: 24px;
  display: flex;
  align-items: center;
}
.salesEngagementAutomationContainer2 {
  top: 401.78px;
  left: 0;
  font-weight: 600;
  width: 401.89px;
  height: 44px;
}
.automatedOneToOneEmail {
  top: 485.78px;
  left: 22.5px;
  width: 208.81px;
  height: 20px;
}
.vectorIcon13 {
  position: absolute;
  height: 1.74%;
  width: 1.26%;
  top: 80.01%;
  right: 98.71%;
  bottom: 18.25%;
  left: 0.03%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.pipelineAutomation {
  position: absolute;
  top: 514.78px;
  left: 22.5px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 144.29px;
  height: 20px;
}
.vectorIcon14 {
  position: absolute;
  height: 1.74%;
  width: 1.26%;
  top: 84.74%;
  right: 98.71%;
  bottom: 13.52%;
  left: 0.03%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.siteTracking {
  position: absolute;
  top: 485.78px;
  left: 277.3px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 89.61px;
  height: 20px;
}
.vectorIcon15 {
  position: absolute;
  height: 1.74%;
  width: 1.26%;
  top: 80.01%;
  right: 74.67%;
  bottom: 18.25%;
  left: 24.07%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.leadScoring1 {
  position: absolute;
  top: 514.78px;
  left: 277.3px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 90.56px;
  height: 20px;
}
.vectorIcon16 {
  position: absolute;
  height: 1.74%;
  width: 1.26%;
  top: 84.74%;
  right: 74.67%;
  bottom: 13.52%;
  left: 24.07%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.aelementorButtonLink4 {
  position: absolute;
  top: 560.78px;
  left: 0;
  border-radius: var(--br-10xs);
  border: 2px solid var(--color-mediumturquoise);
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm) 26.160003662109375px var(--padding-sm)
    var(--padding-6xl);
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  color: var(--color-mediumturquoise);
}
.emailmarketingpngIcon1 {
  position: absolute;
  top: calc(50% - 306.39px);
  left: calc(50% + 34px);
  width: 496px;
  height: 506.67px;
  object-fit: cover;
}
.focusOnQualifiedLeadsParent {
  position: absolute;
  top: calc(50% - 300.81px);
  left: calc(50% - 529.5px);
  width: 1060px;
  height: 612.78px;
}
.body10 {
  align-self: stretch;
  position: relative;
  border-radius: 0 0 var(--br-29xl) var(--br-29xl);
  background-color: var(--color-darkslategray-200);
  height: 831px;
  display: none;
}
.div,
.svgIcon4 {
  position: absolute;
  height: 24px;
}
.div {
  top: 202px;
  left: 109.7px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 8.95px;
}
.svgIcon4 {
  top: 0;
  left: 0;
  width: 24px;
  overflow: hidden;
}
.freeHelpCoursesContainer {
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.h3elementorFlipBoxLayer {
  position: absolute;
  top: 48px;
  left: 0;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-53xl) 0 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.getStarted {
  position: absolute;
  top: 178px;
  left: 0;
  line-height: 24px;
}
.divelementorFlipBoxLayer1 {
  position: absolute;
  height: calc(100% - 48px);
  top: 24px;
  bottom: 24px;
  left: 24px;
  width: 202px;
  font-size: var(--font-size-base);
  font-family: var(--font-ibm-plex-sans);
}
.divelementorFlipBoxLayer {
  position: absolute;
  top: calc(50% + 86px);
  left: calc(50% + 280px);
  background-color: var(--color-mediumturquoise);
  width: 250px;
  height: 250px;
}
.div1 {
  position: absolute;
  top: 202px;
  left: 126.2px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 8.95px;
  height: 24px;
}
.h3elementorFlipBoxLayer1 {
  position: absolute;
  top: 48px;
  left: 0;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-32xl) 0 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.divelementorFlipBoxLayer2 {
  position: absolute;
  top: calc(50% + 86px);
  left: calc(50% + 10px);
  background-color: var(--color-mediumturquoise);
  width: 250px;
  height: 250px;
}
.div2 {
  position: absolute;
  top: 202px;
  left: 112.97px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 8.95px;
  height: 24px;
}
.h3elementorFlipBoxLayer2 {
  position: absolute;
  top: 48px;
  left: 0;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-70xl) 0 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.divelementorFlipBoxLayer4 {
  position: absolute;
  top: calc(50% + 86px);
  left: calc(50% - 260px);
  background-color: var(--color-mediumturquoise);
  width: 250px;
  height: 250px;
}
.div3 {
  position: absolute;
  top: 202px;
  left: 128.34px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 8.95px;
  height: 24px;
}
.h3elementorFlipBoxLayer3 {
  position: absolute;
  top: 48px;
  left: 0;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-2xl) 0 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.divelementorFlipBoxLayer6 {
  position: absolute;
  top: calc(50% + 86px);
  left: calc(50% - 530px);
  background-color: var(--color-mediumturquoise);
  width: 250px;
  height: 250px;
}
.div4,
.landingPages {
  position: absolute;
  line-height: 24px;
}
.div4 {
  top: 202px;
  left: 125.33px;
  display: flex;
  align-items: center;
  width: 8.95px;
  height: 24px;
}
.landingPages {
  top: 48px;
  left: 0;
  font-weight: 500;
}
.divelementorFlipBoxLayer8 {
  position: absolute;
  top: calc(50% - 184px);
  left: calc(50% + 280px);
  background-color: var(--color-mediumturquoise);
  width: 250px;
  height: 250px;
}
.div5 {
  position: absolute;
  top: 202px;
  left: 139.23px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 8.95px;
  height: 24px;
}
.divelementorFlipBoxLayer10 {
  position: absolute;
  top: calc(50% - 184px);
  left: calc(50% + 10px);
  background-color: var(--color-mediumturquoise);
  width: 250px;
  height: 250px;
}
.h3elementorFlipBoxLayer4 {
  position: absolute;
  top: 48px;
  left: 0;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-44xl) 0 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.divelementorFlipBoxLayer12 {
  position: absolute;
  top: calc(50% - 184px);
  left: calc(50% - 260px);
  background-color: var(--color-mediumturquoise);
  width: 250px;
  height: 250px;
}
.div7 {
  position: absolute;
  top: 202px;
  left: 140.75px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 8.95px;
  height: 24px;
}
.divelementorFlipBoxLayer14 {
  position: absolute;
  top: calc(50% - 184px);
  left: calc(50% - 530px);
  background-color: var(--color-mediumturquoise);
  width: 250px;
  height: 250px;
}
.everythingYouNeedContainer {
  position: absolute;
  top: calc(50% - 336px);
  left: calc(50% - 337.41px);
  font-size: var(--font-size-21xl);
  line-height: 48px;
  font-family: var(--font-poppins);
  color: var(--color-darkslategray-200);
  text-align: center;
  display: flex;
  align-items: center;
  width: 675.02px;
  height: 104px;
}
.bodyInner,
.content3 {
  position: relative;
  width: 1060px;
  height: 672px;
}
.bodyInner {
  width: 1920px;
  height: 821px;
  display: none;
}
.body11 {
  align-self: stretch;
  background-color: var(--color-whitesmoke-100);
  height: 821px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-xl) 0 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-sm);
  color: var(--color-white);
  font-family: var(--font-font-awesome-5-free);
}
.teamUpWithContainer,
.with870IntegrationsContainer {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
}
.teamUpWithContainer {
  top: 0;
  line-height: 40px;
  font-family: var(--font-poppins);
  width: 305.68px;
  height: 85px;
}
.with870IntegrationsContainer {
  top: 101px;
  font-size: var(--font-size-lg);
  line-height: 28px;
  width: 336.62px;
  height: 107px;
}
.viewAllApps {
  position: absolute;
  top: 231px;
  left: 5px;
  font-size: var(--font-size-base);
  line-height: 24px;
  font-weight: 500;
}
.div8 {
  position: absolute;
  top: 237px;
  left: 105.89px;
  font-size: var(--font-size-sm);
  line-height: 14px;
  font-family: var(--font-font-awesome-5-free);
  text-align: right;
}
.appsGroup768x366pngIcon {
  position: absolute;
  top: calc(50% - 137.74px);
  left: calc(50% - 76.19px);
  width: 584.38px;
  height: 278.48px;
  object-fit: cover;
}
.teamUpWithYourFavoriteAppParent {
  position: absolute;
  top: calc(50% - 143.53px);
  left: calc(50% - 509.5px);
  width: 1016.38px;
  height: 281.48px;
}
.body12 {
  align-self: stretch;
  position: relative;
  background-color: var(--color-whitesmoke-100);
  height: 352px;
  font-size: var(--font-size-13xl);
  color: var(--color-darkslategray-200);
}
.customerSpotlights {
  position: absolute;
  bottom: 567.49px;
  left: calc(50% - 206.78px);
  font-size: var(--font-size-21xl);
  line-height: 54px;
  display: flex;
  font-family: var(--font-poppins);
  color: var(--color-gray-600);
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 421.76px;
  height: 56px;
}
.child,
.hipcookspngIcon {
  position: absolute;
  width: 251px;
}
.child {
  top: 0.05px;
  left: 0;
  background-color: var(--color-white);
  height: 444px;
}
.hipcookspngIcon {
  top: calc(50% - 214.24px);
  left: calc(50% - 125.5px);
  height: 192.33px;
  object-fit: cover;
}
.hipcooks,
.localizingOurContentContainer {
  position: absolute;
  top: 210.38px;
  left: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 69.47px;
  height: 20px;
}
.localizingOurContentContainer {
  top: 250.43px;
  width: 204.01px;
  height: 116px;
}
.div11,
.seeMore {
  position: absolute;
  color: var(--color-mediumblue);
}
.seeMore {
  top: 404.47px;
  left: 21px;
  line-height: 24px;
  font-weight: 500;
}
.div11 {
  top: 410.47px;
  left: 94.22px;
  font-size: var(--font-size-sm);
  line-height: 14px;
  font-family: var(--font-font-awesome-5-free);
  text-align: right;
}
.div10 {
  top: calc(50% - 222.02px);
  left: calc(50% - 125.5px);
  height: 428.47px;
}
.div10,
.div9,
.msi768x589pngIcon {
  position: absolute;
  width: 251px;
}
.div9 {
  top: 103px;
  left: 0;
  height: 444.05px;
}
.msi768x589pngIcon {
  top: calc(50% - 214.25px);
  left: calc(50% - 125.5px);
  height: 192.48px;
  object-fit: cover;
}
.activecampaignLetsUsContainer,
.museumOfScienceContainer {
  position: absolute;
  top: 210.49px;
  left: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 144.54px;
  height: 44px;
}
.activecampaignLetsUsContainer {
  top: 274.49px;
  width: 206.15px;
  height: 92px;
}
.div14,
.seeMore1 {
  position: absolute;
  color: var(--color-mediumblue);
}
.seeMore1 {
  top: 404.49px;
  left: 21px;
  line-height: 24px;
  font-weight: 500;
}
.div14 {
  top: 410.49px;
  left: 94.22px;
  font-size: var(--font-size-sm);
  line-height: 14px;
  font-family: var(--font-font-awesome-5-free);
  text-align: right;
}
.div12,
.div13 {
  position: absolute;
  width: 251px;
}
.div13 {
  top: calc(50% - 222.02px);
  left: calc(50% - 125.5px);
  height: 428.49px;
}
.div12 {
  top: 103px;
  left: 259px;
  height: 444.05px;
}
.evenThoughImContainer,
.marrowAudio {
  position: absolute;
  top: 210.49px;
  left: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 105.18px;
  height: 20px;
}
.evenThoughImContainer {
  top: 250.49px;
  width: 207.43px;
  height: 116px;
}
.beefy768x589pngIcon,
.div15 {
  position: absolute;
  width: 251px;
}
.div15 {
  top: 103px;
  left: 518px;
  height: 444.05px;
}
.beefy768x589pngIcon {
  top: calc(50% - 214.24px);
  left: calc(50% - 125.5px);
  height: 192.48px;
  object-fit: cover;
}
.activecampaignHadTheContainer,
.beefyMarketing {
  position: absolute;
  top: 215.82px;
  left: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 122.65px;
  height: 20px;
}
.activecampaignHadTheContainer {
  top: 261.14px;
  width: 218.98px;
  height: 116px;
}
.div18 {
  position: absolute;
  top: 103px;
  left: 777px;
  width: 251px;
  height: 444.05px;
}
.seeAllCustomer {
  top: 0;
  left: 0;
  line-height: 24px;
  font-weight: 500;
}
.div21,
.seeAllCustomer,
.seemore {
  position: absolute;
}
.div21 {
  top: 6px;
  left: 220.61px;
  font-size: var(--font-size-sm);
  line-height: 14px;
  font-family: var(--font-font-awesome-5-free);
  text-align: right;
}
.seemore {
  top: 599.49px;
  left: 401.81px;
  width: 229.61px;
  height: 24px;
  text-align: center;
  color: var(--color-gray-600);
}
.customerSpotlightsParent {
  position: relative;
  width: 1028px;
  height: 623.49px;
  z-index: 0;
}
.testimonial {
  align-self: stretch;
  background-color: var(--color-whitesmoke-100);
  height: 721px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color-darkslategray-100);
}
.noCreditCard,
.tryItNow {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 48px;
  display: flex;
  align-items: center;
  width: 364.58px;
  height: 56px;
}
.noCreditCard {
  top: 70px;
  font-size: var(--font-size-lg);
  line-height: 28px;
  font-family: var(--font-ibm-plex-sans);
  width: 308.56px;
  height: 23px;
}
.emailAddress,
.tryItNowForFreeParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 364.58px;
  height: 93px;
}
.emailAddress {
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 126.31px;
  height: 25px;
}
.divplaceholder {
  position: absolute;
  top: 19.5px;
  left: 16px;
  width: 274.47px;
  height: 25px;
  overflow: hidden;
}
.inputacInput {
  position: absolute;
  width: calc(100% - 233.53px);
  top: 0;
  right: 233.53px;
  left: 0;
  border-radius: var(--br-8xs) 0 0 var(--br-8xs);
  background-color: var(--color-white);
  height: 64px;
}
.startYourFree {
  position: relative;
  line-height: 32px;
}
.buttonactButton2 {
  position: absolute;
  top: 0;
  left: 306.47px;
  border-radius: 0 var(--br-8xs) var(--br-8xs) 0;
  background-color: var(--color-mediumblue);
  display: flex;
  flex-direction: row;
  padding: var(--padding-base) 35.47999954223633px var(--padding-base)
    35.04999923706055px;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  color: var(--color-white);
}
.inputacInputParent {
  position: absolute;
  top: 26px;
  left: 540px;
  width: 540px;
  height: 64px;
  font-size: var(--font-size-lgi-2);
  color: var(--color-lightslategray);
  font-family: var(--font-ibm-plex-sans);
}
.frameParent4 {
  position: absolute;
  top: calc(50% - 63.56px);
  left: calc(50% - 539.5px);
  width: 1080px;
  height: 93px;
}
.body13,
.ourCustomersLove {
  font-family: var(--font-poppins);
}
.body13 {
  align-self: stretch;
  position: relative;
  background-color: var(--color-darkslategray-200);
  height: 216px;
  font-size: var(--font-size-21xl);
  color: var(--color-white);
}
.ourCustomersLove {
  position: absolute;
  top: 0;
  left: 287.63px;
  line-height: 61.6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 504.93px;
  height: 61px;
}
.divelementorContainerIcon {
  position: absolute;
  top: 93.59px;
  left: calc(50% - 540px);
  width: 1080px;
  height: 142px;
}
.dontJustTakeContainer {
  position: absolute;
  top: 34px;
  left: 242.28px;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 595.64px;
  height: 51px;
}
.divelementorWidgetContainer {
  position: absolute;
  top: 235.59px;
  left: 0;
  width: 1080px;
  height: 88px;
  font-size: var(--font-size-lg);
}
.seeReviews {
  top: 0;
  left: -1px;
  line-height: 32px;
  font-weight: 500;
}
.a,
.div22,
.seeReviews {
  position: absolute;
}
.div22 {
  top: 10px;
  left: 113.79px;
  font-size: var(--font-size-sm);
  line-height: 14px;
  font-family: var(--font-font-awesome-5-free);
  text-align: right;
}
.a {
  top: calc(50% + 155.79px);
  left: calc(50% - 62.77px);
  width: 125.53px;
  height: 32px;
  font-size: var(--font-size-xl);
  color: var(--color-mediumblue);
}
.divelementorWidgetWrap4 {
  position: absolute;
  top: 41.44px;
  left: calc(50% - 539.5px);
  width: 1080px;
  height: 375.59px;
}
.testimonial1 {
  align-self: stretch;
  position: relative;
  background-color: var(--color-gainsboro);
  height: 463px;
  text-align: center;
  font-size: var(--font-size-25xl);
  color: var(--color-darkslategray-100);
}
.div23,
.div24,
.div25,
.div26 {
  position: absolute;
  top: 0;
  left: 3px;
  line-height: 26px;
}
.div24,
.div25,
.div26 {
  left: 46.5px;
}
.div25,
.div26 {
  left: 90px;
}
.div26 {
  left: 133.5px;
}
.ulelementorIconListItems6 {
  position: absolute;
  top: 0;
  left: -3px;
  width: 174px;
  height: 26px;
}
.divelementorWidgetContainer1 {
  position: absolute;
  top: 10px;
  left: 16px;
  border-right: 1px solid var(--color-silver);
  box-sizing: border-box;
  width: 169px;
  height: 26px;
  overflow: hidden;
}
.div27 {
  position: relative;
  line-height: 26px;
}
.ulelementorIconListItems7 {
  position: absolute;
  top: 0;
  left: -3px;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-mini-5) 0 var(--padding-10xs);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-4xl-5);
}
.divelementorWidgetContainer2 {
  position: absolute;
  top: 10px;
  left: 197px;
  border-right: 1px solid var(--color-silver);
  box-sizing: border-box;
  width: 82px;
  height: 26px;
  overflow: hidden;
}
.blueSeal20042Bbb88495326Icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 142px;
  height: 30px;
  object-fit: cover;
}
.divelementorElement {
  position: absolute;
  top: calc(50% - 15px);
  left: 291px;
  border-right: 1px solid var(--color-silver);
  box-sizing: border-box;
  width: 147.8px;
  height: 30px;
}
.legalCenter {
  position: relative;
  line-height: 20px;
}
.divelementorWidgetContainer3,
.divelementorWidgetContainer4,
.divlegalCenter {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.divelementorWidgetContainer3 {
  overflow: hidden;
}
.divelementorWidgetContainer4,
.divlegalCenter {
  position: absolute;
  font-size: var(--font-size-sm);
  font-family: var(--font-ibm-plex-sans);
}
.divlegalCenter {
  top: calc(50% - 13px);
  left: 450.8px;
  border-right: 1px solid var(--color-silver);
  padding: 0 12.5199966430664var (--padding-7xs) 6px 0;
}
.divelementorWidgetContainer4 {
  top: 10px;
  left: 554.31px;
  overflow: hidden;
}
.divelementorWidgetWrap5 {
  position: absolute;
  width: calc(100% - 373.78px);
  top: 0;
  right: 373.78px;
  left: 0;
  height: 46px;
}
.div29 {
  position: absolute;
  top: 15px;
  left: 17px;
  line-height: 18px;
}
.search {
  position: relative;
  font-weight: 500;
}
.divplaceholder1 {
  position: absolute;
  top: 14px;
  left: 43px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 0 151.4199981689453px 0 0;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-sm);
  color: var(--color-lightslategray);
  font-family: var(--font-ibm-plex-sans);
}
.divactSearchContainer {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-9xs-2);
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-200);
  box-sizing: border-box;
  width: 270.42px;
  height: 46px;
}
.search1 {
  position: relative;
  line-height: 28px;
}
.buttonactSearchSubmit {
  position: absolute;
  top: 0;
  left: 274.42px;
  border-radius: var(--br-9xs-2);
  background-color: var(--color-white);
  border: 2px solid var(--color-gray-400);
  display: flex;
  flex-direction: row;
  padding: var(--padding-4xs) 18.55999755859375px var(--padding-4xs)
    var(--padding-mid);
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--font-size-base);
  font-family: var(--font-ibm-plex-sans);
}
.formactSearchForm {
  position: absolute;
  top: 0;
  left: 706.22px;
  border-radius: var(--br-9xs);
  width: 359.98px;
  height: 46px;
  font-size: var(--font-size-lg);
  color: var(--color-gray-400);
  font-family: var(--font-font-awesome-5-free);
}
.divelementorContainer1 {
  position: absolute;
  top: calc(50% - 23px);
  left: calc(50% - 539.5px);
  width: 1080px;
  height: 46px;
}
.english1,
.social1 {
  position: absolute;
  left: 0;
}
.social1 {
  width: 100%;
  right: 0;
  bottom: 0;
  background-color: var(--color-ghostwhite);
  height: 110px;
  font-size: var(--font-size-7xl);
  color: var(--color-dimgray);
  font-family: var(--font-font-awesome-5-brands);
}
.english1 {
  top: 0;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45.79px;
  height: 18px;
}
.spanwpmlLsNative {
  position: absolute;
  top: calc(50% - 6.8px);
  left: calc(50% - 229.36px);
  border-bottom: 2px solid var(--color-gray-400);
  box-sizing: border-box;
  width: 45.59px;
  height: 24px;
  font-size: var(--font-size-sm);
}
.div30,
.espaol {
  position: absolute;
  top: 0;
  left: 2px;
  line-height: 28px;
}
.espaol {
  top: 8.39px;
  left: 19.16px;
  font-size: var(--font-size-sm);
  line-height: 16px;
}
.liwpmlLsSlotFooter {
  position: absolute;
  top: calc(50% - 14.19px);
  left: calc(50% - 173.77px);
  width: 79.34px;
  height: 28.39px;
}
.franais {
  position: absolute;
  top: 8.39px;
  left: 19.15px;
  font-size: var(--font-size-sm);
  line-height: 16px;
}
.liwpmlLsSlotFooter1,
.liwpmlLsSlotFooter2 {
  position: absolute;
  top: calc(50% - 14.19px);
  left: calc(50% - 94.42px);
  width: 82.06px;
  height: 28.39px;
}
.liwpmlLsSlotFooter2 {
  left: calc(50% - 12.36px);
  width: 77.73px;
}
.deutsch {
  position: absolute;
  top: 8.39px;
  left: 18.15px;
  font-size: var(--font-size-sm);
  line-height: 16px;
}
.liwpmlLsSlotFooter3 {
  top: calc(50% - 14.19px);
  left: calc(50% + 65.38px);
  width: 80.92px;
}
.liwpmlLsSlotFooter3,
.liwpmlLsSlotFooter4,
.ul {
  position: absolute;
  height: 28.39px;
}
.liwpmlLsSlotFooter4 {
  top: calc(50% - 14.19px);
  left: calc(50% + 146.3px);
  width: 93.06px;
}
.ul {
  top: calc(50% - 15.19px);
  left: 8px;
  width: 1064px;
}
.sectionelementorSection1 {
  position: absolute;
  top: 0;
  left: calc(50% - 539.5px);
  border-top: 1px solid var(--color-silver);
  border-bottom: 1px solid var(--color-silver);
  box-sizing: border-box;
  width: 1080px;
  height: 84.39px;
}
.language {
  position: absolute;
  width: 100%;
  top: 925.56px;
  right: 0;
  left: 0;
  background-color: var(--color-ghostwhite);
  height: 84.39px;
  text-align: center;
  font-size: var(--font-size-lg);
}
.customerExperienceAutomationContainer {
  position: relative;
  letter-spacing: 0.6px;
  line-height: 18px;
  text-transform: uppercase;
}
.a1 {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.emailMarketingAutomation,
.marketingAutomation,
.salesAutomation,
.salesEngagement1,
.serviceSupport {
  position: absolute;
  top: 5px;
  left: 0;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 178.08px;
  height: 18px;
}
.marketingAutomation,
.salesAutomation,
.salesEngagement1,
.serviceSupport {
  top: 43px;
  width: 139.61px;
}
.salesAutomation,
.salesEngagement1,
.serviceSupport {
  top: 81px;
  width: 110.97px;
}
.salesEngagement1,
.serviceSupport {
  top: 119px;
  width: 116.14px;
}
.serviceSupport {
  top: 157px;
  width: 112.93px;
}
.ulelementorIconListItems8 {
  position: absolute;
  top: 55.19px;
  left: 0;
  width: 216px;
  height: 180px;
  font-size: var(--font-size-sm);
  color: var(--color-dimgray);
}
.divelementorWidgetWrap6 {
  position: absolute;
  width: calc(100% - 864px);
  top: 0;
  right: 864px;
  left: 0;
  height: 484.38px;
}
.landingPages1 {
  position: absolute;
  top: 5px;
  left: 0;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 90.53px;
  height: 18px;
}
.liveChat,
.smsMarketing,
.socialMediaMarketing,
.transactionalEmail,
.webPersonalization {
  position: absolute;
  top: 43px;
  left: 0;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 57.53px;
  height: 18px;
}
.smsMarketing,
.socialMediaMarketing,
.transactionalEmail,
.webPersonalization {
  top: 81px;
  width: 93.72px;
}
.socialMediaMarketing,
.transactionalEmail,
.webPersonalization {
  top: 119px;
  width: 145.33px;
}
.transactionalEmail,
.webPersonalization {
  top: 157px;
  width: 128.18px;
}
.transactionalEmail {
  top: 195px;
  width: 123.39px;
}
.ulelementorIconListItems9 {
  position: absolute;
  top: 55.19px;
  left: 0;
  width: 216px;
  height: 218px;
  font-size: var(--font-size-sm);
  color: var(--color-dimgray);
}
.divelementorWidgetWrap7 {
  position: absolute;
  width: calc(100% - 864px);
  top: 0;
  right: 648px;
  left: 216px;
  height: 484.38px;
}
.a3 {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  padding: 0 0.1999969482421875px 0 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.getStarted1,
.liveExpertWorkshop {
  position: absolute;
  top: 5px;
  left: 0;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 72.23px;
  height: 18px;
}
.liveExpertWorkshop {
  top: 43px;
  width: 136.17px;
}
.blog,
.community,
.events,
.helpResources,
.marketingGlossary,
.productUpdates,
.strategySession {
  position: absolute;
  top: 81px;
  left: 0;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 128.97px;
  height: 18px;
}
.blog,
.community,
.events,
.helpResources,
.marketingGlossary,
.productUpdates {
  top: 119px;
  width: 72.5px;
}
.blog,
.events,
.helpResources,
.marketingGlossary,
.productUpdates {
  top: 157px;
  width: 42.51px;
}
.blog,
.helpResources,
.marketingGlossary,
.productUpdates {
  top: 195px;
  width: 98.61px;
}
.blog,
.marketingGlossary,
.productUpdates {
  top: 233px;
  width: 105.36px;
}
.blog,
.marketingGlossary {
  top: 271px;
  width: 120.75px;
}
.blog {
  top: 309px;
  width: 28.39px;
}
.ulelementorIconListItems10 {
  position: absolute;
  top: 55.19px;
  left: 0;
  width: 216px;
  height: 332px;
  font-size: var(--font-size-sm);
  color: var(--color-dimgray);
}
.divelementorWidgetWrap8 {
  position: absolute;
  width: calc(100% - 864px);
  top: 0;
  right: 432px;
  left: 432px;
  height: 484.38px;
}
.a4 {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  padding: 0 0.4199981689453125px 0 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.automationRecipes,
.developerCenter,
.freeTools,
.integrationMarketplace {
  position: absolute;
  top: 5px;
  left: 0;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 151.53px;
  height: 18px;
}
.automationRecipes,
.developerCenter,
.freeTools {
  top: 43px;
  width: 109.54px;
}
.automationRecipes,
.freeTools {
  top: 81px;
  width: 64.9px;
}
.automationRecipes {
  top: 119px;
  width: 126.64px;
}
.ulelementorIconListItems11 {
  position: absolute;
  top: 55.19px;
  left: 0;
  width: 216px;
  height: 142px;
  font-size: var(--font-size-sm);
  color: var(--color-dimgray);
}
.divelementorWidgetWrap10 {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  height: 197.19px;
}
.becomeAPartner {
  position: absolute;
  top: 33px;
  left: 0;
  letter-spacing: 0.6px;
  line-height: 18px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  width: 126.4px;
  height: 15px;
}
.affiliate,
.agencyReseller,
.isvDeveloper {
  position: absolute;
  top: 5px;
  left: 0;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 113.79px;
  height: 18px;
}
.affiliate,
.isvDeveloper {
  top: 43px;
  width: 48.98px;
}
.isvDeveloper {
  top: 81px;
  width: 102.81px;
}
.ulelementorIconListItems12 {
  position: absolute;
  top: 69.18px;
  left: 0;
  width: 216px;
  height: 104px;
  font-size: var(--font-size-sm);
  color: var(--color-dimgray);
}
.divelementorWidgetWrap11 {
  position: absolute;
  width: 100%;
  top: 197.19px;
  right: 0;
  left: 0;
  height: 173.19px;
}
.divelementorWidgetWrap9 {
  position: absolute;
  width: calc(100% - 864px);
  top: 0;
  right: 216px;
  left: 648px;
  height: 484.38px;
}
.careers,
.customerStories,
.faq,
.howWeCompare,
.newsroom,
.whyActivecampaign {
  position: absolute;
  top: 5px;
  left: 0;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 132.53px;
  height: 18px;
}
.careers,
.customerStories,
.faq,
.howWeCompare,
.newsroom {
  top: 43px;
  width: 112.29px;
}
.careers,
.customerStories,
.faq,
.newsroom {
  top: 81px;
  width: 108.59px;
}
.careers,
.faq,
.newsroom {
  top: 119px;
  width: 48.78px;
}
.faq,
.newsroom {
  top: 157px;
  width: 26.09px;
}
.newsroom {
  top: 195px;
  width: 68.23px;
}
.divelementorWidgetWrap13 {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  height: 273.19px;
}
.learnMore {
  position: absolute;
  top: 33px;
  left: 0;
  letter-spacing: 0.6px;
  line-height: 18px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  width: 81.45px;
  height: 15px;
}
.contactUs,
.freeTrial,
.pricing2,
.requestADemo {
  position: absolute;
  top: 5px;
  left: 0;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 68.65px;
  height: 18px;
}
.freeTrial,
.pricing2,
.requestADemo {
  top: 43px;
  width: 103.26px;
}
.freeTrial,
.pricing2 {
  top: 81px;
  width: 58.79px;
}
.pricing2 {
  top: 119px;
  width: 43.08px;
}
.ulelementorIconListItems14 {
  position: absolute;
  top: 69.18px;
  left: 0;
  width: 216px;
  height: 142px;
  font-size: var(--font-size-sm);
  color: var(--color-dimgray);
}
.divelementorWidgetWrap14 {
  position: absolute;
  width: 100%;
  top: 273.19px;
  right: 0;
  left: 0;
  height: 211.19px;
}
.divelementorWidgetWrap12 {
  position: absolute;
  width: calc(100% - 864px);
  top: 0;
  right: 0;
  left: 864px;
  height: 484.38px;
}
.divelementorContainer2 {
  position: absolute;
  top: calc(50% - 226.19px);
  left: calc(50% - 539.5px);
  width: 1080px;
  height: 484.38px;
}
.detailedCate {
  position: absolute;
  width: 100%;
  top: 345.19px;
  right: 0;
  left: 0;
  background-color: var(--color-ghostwhite);
  height: 580.38px;
}
.supportingYouAtContainer {
  position: absolute;
  top: 2px;
  left: 0;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 150.12px;
  height: 79px;
}
.h4elementorHeadingTitle {
  position: absolute;
  top: 0;
  left: 0;
  width: 160.88px;
  height: 84px;
  font-size: var(--font-size-lg);
}
.reach1 {
  position: absolute;
  top: 1px;
  left: 0;
  letter-spacing: 0.6px;
  line-height: 18px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  width: 42.93px;
  height: 15px;
}
.createForms,
.emailNewsletter,
.emailTemplates,
.leadGeneration,
.popUpBuilder {
  position: absolute;
  top: 5px;
  left: 0;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 104.45px;
  height: 18px;
}
.createForms,
.emailNewsletter,
.leadGeneration,
.popUpBuilder {
  top: 43px;
  width: 107.7px;
}
.createForms,
.leadGeneration,
.popUpBuilder {
  top: 81px;
  width: 84.48px;
}
.leadGeneration,
.popUpBuilder {
  top: 119px;
  width: 93.93px;
}
.leadGeneration {
  top: 157px;
  width: 103.48px;
}
.ulelementorIconListItems15 {
  position: absolute;
  top: 37.19px;
  left: 0;
  width: 216px;
  height: 180px;
  font-size: var(--font-size-sm);
  color: var(--color-dimgray);
}
.divelementorWidgetWrap15 {
  position: absolute;
  width: calc(100% - 864px);
  top: 0;
  right: 648px;
  left: 216px;
  height: 217.19px;
}
.nurture1 {
  position: absolute;
  top: 1px;
  left: 0;
  letter-spacing: 0.6px;
  line-height: 18px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  width: 59.93px;
  height: 15px;
}
.conversationalMarketing,
.facebookAdvertising,
.leadTrackingSoftware,
.socialMediaAdvertising,
.wordpress {
  position: absolute;
  top: 5px;
  left: 0;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 159.84px;
  height: 18px;
}
.facebookAdvertising,
.leadTrackingSoftware,
.socialMediaAdvertising,
.wordpress {
  top: 43px;
  width: 134.9px;
}
.leadTrackingSoftware,
.socialMediaAdvertising,
.wordpress {
  top: 81px;
  width: 153.59px;
}
.leadTrackingSoftware,
.wordpress {
  top: 119px;
  width: 146.37px;
}
.wordpress {
  top: 5px;
  left: 22.5px;
  width: 67.92px;
}
.a6,
.svgIcon12 {
  position: absolute;
  left: 0;
}
.svgIcon12 {
  top: 7px;
  width: 14px;
  height: 14px;
  overflow: hidden;
}
.a6 {
  width: 100%;
  top: calc(50% + 62px);
  right: 0;
  height: 28px;
}
.divelementorWidgetWrap16 {
  position: absolute;
  width: calc(100% - 864px);
  top: 0;
  right: 432px;
  left: 432px;
  height: 217.19px;
}
.convertGrow {
  position: absolute;
  top: 1px;
  left: 0;
  letter-spacing: 0.6px;
  line-height: 18px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  width: 114.29px;
  height: 15px;
}
.attributionSoftware,
.leadScoring2,
.salesAnalytics,
.salesPlatform,
.salesforce {
  position: absolute;
  top: 5px;
  left: 0;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 91.65px;
  height: 18px;
}
.attributionSoftware,
.leadScoring2,
.salesAnalytics,
.salesforce {
  top: 43px;
  width: 94.76px;
}
.attributionSoftware,
.leadScoring2,
.salesforce {
  top: 81px;
  width: 80.58px;
}
.attributionSoftware,
.salesforce {
  top: 119px;
  width: 127.25px;
}
.salesforce {
  top: 5px;
  left: 22.5px;
  width: 66.09px;
}
.divelementorWidgetWrap17 {
  position: absolute;
  width: calc(100% - 864px);
  top: 0;
  right: 216px;
  left: 648px;
  height: 217.19px;
}
.ecommerce {
  position: absolute;
  top: 1px;
  left: 0;
  letter-spacing: 0.6px;
  line-height: 18px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  width: 79.12px;
  height: 15px;
}
.ecommerceMarketing,
.ecommercePersonalization,
.shoppingCartAbandonment {
  position: absolute;
  top: 5px;
  left: 0;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 140.75px;
  height: 18px;
}
.ecommercePersonalization,
.shoppingCartAbandonment {
  top: 43px;
  width: 174.78px;
}
.shoppingCartAbandonment {
  top: 81px;
  width: 181.31px;
}
.a8,
.shopify {
  position: absolute;
}
.shopify {
  top: 5px;
  left: 22.5px;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 47.42px;
  height: 18px;
}
.a8 {
  width: 100%;
  top: calc(50% + 24px);
  right: 0;
  left: 0;
  height: 28px;
}
.woocommerce {
  position: absolute;
  top: 5px;
  left: 22.5px;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 96.53px;
  height: 18px;
}
.divelementorWidgetWrap18 {
  position: absolute;
  width: calc(100% - 864px);
  top: 0;
  right: 0;
  left: 864px;
  height: 217.19px;
}
.divelementorContainer3 {
  position: absolute;
  top: calc(50% - 108.6px);
  left: calc(50% - 539.5px);
  width: 1080px;
  height: 217.19px;
}
.touachpoint {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  background-color: var(--color-aliceblue);
  height: 345.19px;
}
.footer {
  align-self: stretch;
  position: relative;
  height: 1119.95px;
  font-size: var(--font-size-xs);
  color: var(--color-gray-400);
}
.contentifyDraft20230521 {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  background-color: var(--color-whitesmoke-300);
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.01);
  height: 11030px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.ver1CurrentCopy {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  height: 11030px;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-gray-100);
  font-family: var(--font-ibm-plex-sans);
}
@media screen and (max-width: 1200px) {
  .sectionelementorSection {
    mix-blend-mode: normal;
    background-color: var(--color-orange-100);
    flex: 1;
    flex-direction: column;
    gap: var(--gap-0);
  }
  .top {
    gap: var(--gap-8xs);
  }
  .login {
    display: none;
  }
  .the1ContentContainer {
    line-height: 52px;
  }
  .body6 {
    flex: 1;
  }
}
@media screen and (max-width: 960px) {
  .sectionelementorSection {
    display: none;
    width: auto;
    align-self: unset;
    height: auto;
    flex-direction: column;
  }
  .top {
    gap: var(--gap-8xs);
  }
  .top,
  .vectorStrokeIcon {
    display: flex;
  }
  .empowerYourBusinessContainer {
    font-size: var(--font-size-16xl);
    line-height: 50px;
    width: 591px;
  }
  .imageCrop1Icon {
    display: none;
  }
  .body {
    align-self: stretch;
    width: auto;
    flex: 1;
    flex-direction: column;
    gap: var(--gap-3xs);
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 0;
  }
}
@media screen and (max-width: 420px) {
  .sectionelementorSection {
    display: none;
    width: auto;
    align-self: unset;
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-top: 0;
    box-sizing: border-box;
  }
  .top {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .empowerYourBusinessContainer {
    font-size: var(--font-size-xs);
    line-height: 2px;
    text-align: left;
    width: 591px;
  }
}
@media screen and (max-width: 1188px) {
  .buttonactButtonWrapper {
    display: none;
  }
}
@media screen and (max-width: 1289px) {
  .buttonactButtonWrapper {
    display: none;
  }
}
@media screen and (max-width: 1493px) {
  .buttonactButtonWrapper {
    display: flex;
  }
}
@media screen and (max-width: 1148px) {
  .vectorStrokeIcon {
    display: none;
  }
}
@media screen and (max-width: 770px) {
  .buttonactButtonWrapper {
    display: none;
  }
}
@media screen and (max-width: 1410px) {
  .login {
    display: none;
  }
}
@media screen and (max-width: 1182px) {
  .catgoryWrapper {
    display: none;
  }
  .vectorStrokeIcon {
    display: flex;
  }
}

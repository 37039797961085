.div,
.svgIcon {
  position: absolute;
  height: 24px;
}
.div {
  top: 202px;
  left: 109.7px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 8.95px;
}
.svgIcon {
  top: 0;
  left: 0;
  width: 24px;
  overflow: hidden;
}
.freeHelpCourses {
  margin: 0;
}
.freeHelpCoursesContainer {
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.h3elementorFlipBoxLayer {
  position: absolute;
  top: 48px;
  left: 0;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-53xl) 0 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.getStarted {
  position: absolute;
  top: 178px;
  left: 0;
  line-height: 24px;
}
.divelementorFlipBoxLayer1 {
  position: absolute;
  height: calc(100% - 48px);
  top: 24px;
  bottom: 24px;
  left: 24px;
  width: 202px;
  font-size: var(--font-size-base);
  font-family: var(--font-ibm-plex-sans);
}
.divelementorFlipBoxLayer {
  position: absolute;
  top: calc(50% + 86px);
  left: calc(50% + 280px);
  background-color: var(--color-mediumturquoise);
  width: 250px;
  height: 250px;
}
.div1 {
  position: absolute;
  top: 202px;
  left: 126.2px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 8.95px;
  height: 24px;
}
.h3elementorFlipBoxLayer1 {
  position: absolute;
  top: 48px;
  left: 0;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-32xl) 0 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.divelementorFlipBoxLayer2 {
  position: absolute;
  top: calc(50% + 86px);
  left: calc(50% + 10px);
  background-color: var(--color-mediumturquoise);
  width: 250px;
  height: 250px;
}
.div2 {
  position: absolute;
  top: 202px;
  left: 112.97px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 8.95px;
  height: 24px;
}
.h3elementorFlipBoxLayer2 {
  position: absolute;
  top: 48px;
  left: 0;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-70xl) 0 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.divelementorFlipBoxLayer4 {
  position: absolute;
  top: calc(50% + 86px);
  left: calc(50% - 260px);
  background-color: var(--color-mediumturquoise);
  width: 250px;
  height: 250px;
}
.div3 {
  position: absolute;
  top: 202px;
  left: 128.34px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 8.95px;
  height: 24px;
}
.h3elementorFlipBoxLayer3 {
  position: absolute;
  top: 48px;
  left: 0;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-2xl) 0 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.divelementorFlipBoxLayer6 {
  position: absolute;
  top: calc(50% + 86px);
  left: calc(50% - 530px);
  background-color: var(--color-mediumturquoise);
  width: 250px;
  height: 250px;
}
.div4,
.landingPages {
  position: absolute;
  line-height: 24px;
}
.div4 {
  top: 202px;
  left: 125.33px;
  display: flex;
  align-items: center;
  width: 8.95px;
  height: 24px;
}
.landingPages {
  top: 48px;
  left: 0;
  font-weight: 500;
}
.divelementorFlipBoxLayer8 {
  position: absolute;
  top: calc(50% - 184px);
  left: calc(50% + 280px);
  background-color: var(--color-mediumturquoise);
  width: 250px;
  height: 250px;
}
.div5 {
  position: absolute;
  top: 202px;
  left: 139.23px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 8.95px;
  height: 24px;
}
.divelementorFlipBoxLayer10 {
  position: absolute;
  top: calc(50% - 184px);
  left: calc(50% + 10px);
  background-color: var(--color-mediumturquoise);
  width: 250px;
  height: 250px;
}
.h3elementorFlipBoxLayer4 {
  position: absolute;
  top: 48px;
  left: 0;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-44xl) 0 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.divelementorFlipBoxLayer12 {
  position: absolute;
  top: calc(50% - 184px);
  left: calc(50% - 260px);
  background-color: var(--color-mediumturquoise);
  width: 250px;
  height: 250px;
}
.div7 {
  position: absolute;
  top: 202px;
  left: 140.75px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 8.95px;
  height: 24px;
}
.divelementorFlipBoxLayer14 {
  position: absolute;
  top: calc(50% - 184px);
  left: calc(50% - 530px);
  background-color: var(--color-mediumturquoise);
  width: 250px;
  height: 250px;
}
.everythingYouNeedContainer1 {
  line-break: anywhere;
  width: 100%;
}
.everythingYouNeedContainer {
  position: absolute;
  top: calc(50% - 336px);
  left: calc(50% - 337.41px);
  font-size: var(--font-size-21xl);
  line-height: 48px;
  font-family: var(--font-poppins);
  color: var(--color-darkslategray-200);
  text-align: center;
  display: flex;
  align-items: center;
  width: 675.02px;
  height: 104px;
}
.bodyChild,
.content3 {
  position: relative;
  width: 1060px;
  height: 672px;
}
.bodyChild {
  width: 1920px;
  height: 821px;
  display: none;
}
.body {
  align-self: stretch;
  background-color: var(--color-whitesmoke-100);
  height: 821px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-xl) 0 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-white);
  font-family: var(--font-font-awesome-5-free);
}

.contentiifyIcon {
  position: absolute;
  top: calc(50% - 14px);
  left: calc(50% - 664.25px);
  width: 161.89px;
  height: 28px;
}
.features,
.icon {
  position: relative;
}
.features {
  line-height: 14px;
}
.icon {
  width: 8.13px;
  height: 4.62px;
  mix-blend-mode: normal;
}
.platform,
.products {
  height: 72px;
  box-sizing: border-box;
}
.products {
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.01);
  width: 97.05px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-10xl) var(--padding-mini);
  align-items: center;
  justify-content: center;
  gap: var(--gap-6xs);
}
.platform {
  width: 95.75px;
  gap: var(--gap-8xs);
}
.platform,
.pricing {
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.01);
  display: flex;
  flex-direction: row;
  padding: var(--padding-10xl) var(--padding-xs);
  align-items: center;
  justify-content: center;
}
.catgory,
.catgoryWrapper,
.contentiifyParent {
  position: absolute;
  top: calc(50% - 36px);
  height: 72px;
}
.catgory {
  left: calc(50% - 324.5px);
  width: 597px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xl);
}
.catgoryWrapper,
.contentiifyParent {
  left: calc(50% - 307.25px);
  width: 615px;
}
.contentiifyParent {
  left: calc(50% - 663.5px);
  width: 1328.5px;
}
.frameWrapper {
  position: absolute;
  top: calc(50% - 35.77px);
  left: 133px;
  width: 1325px;
  height: 72px;
}
.vectorIcon {
  width: 20px;
  height: 20px;
}
.english,
.icon2,
.vectorIcon {
  position: relative;
}
.english {
  line-height: 20px;
  font-weight: 500;
}
.icon2 {
  width: 8.13px;
  height: 4.62px;
}
.vectorParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.login1 {
  position: relative;
  line-height: 28px;
  font-weight: 500;
  text-align: left;
}
.login {
  position: absolute;
  height: calc(100% - 44px);
  top: 22.23px;
  right: 255px;
  bottom: 21.77px;
  width: 227px;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-4xl) 0 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-xl);
  text-align: center;
}
.buttonactButton {
  position: absolute;
  top: 0;
  right: 0;
}
.buttonactButtonWrapper {
  position: absolute;
  height: calc(100% - 32px);
  top: 16.23px;
  right: 91px;
  bottom: 15.77px;
  width: 129px;
}
.vectorStrokeIcon {
  position: absolute;
  height: calc(100% - 51px);
  top: 22.5px;
  right: 34px;
  bottom: 28.5px;
  max-height: 100%;
  width: 21px;
  display: none;
}
.frameParent {
  position: relative;
  width: 1920px;
  height: 72px;
}
.contentifyDraft20230521Inner {
  align-self: stretch;
  background-color: var(--color-darkslategray-200);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.empowerYourBusiness {
  margin: 0;
}
.empowerYourBusinessContainer1 {
  line-break: anywhere;
  width: 100%;
}
.empowerYourBusinessContainer {
  position: absolute;
  width: 100%;
  top: calc(50% - 87px);
  left: 0;
  line-height: 81.1px;
  display: flex;
  align-items: center;
  height: 173.89px;
}
.groupChild {
  position: absolute;
  width: calc(100% - 345.75px);
  top: calc(50% + 5.5px);
  right: 197.32px;
  left: 148.44px;
  max-width: 100%;
  overflow: hidden;
  height: 83px;
}
.empowerYourBusinessWithVirParent {
  position: absolute;
  margin: 0 !important;
  width: calc(100% - 2.06px);
  top: calc(50% - 199.5px);
  right: 0;
  left: 2.06px;
  height: 174px;
  z-index: 0;
  font-family: var(--font-poppins);
}
.theSocialMediaContainer {
  position: relative;
  font-size: var(--font-size-xl);
  line-height: 32px;
  display: flex;
  align-items: center;
  width: 776.5px;
  z-index: 1;
}
.getViral {
  position: relative;
  line-height: 32px;
  font-weight: 500;
}
.buttonactButton1 {
  border-radius: var(--br-8xs);
  background-color: var(--color-mediumturquoise);
  height: 48px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) 44.51000213623047px var(--padding-5xs)
    var(--padding-26xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  z-index: 2;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--color-darkslategray-200);
}
.groupParent,
.tryItFree {
  display: flex;
  position: relative;
}
.tryItFree {
  font-size: var(--font-size-sm);
  line-height: 17.5px;
  align-items: center;
  width: 776.5px;
  z-index: 3;
}
.groupParent {
  width: 828px;
  flex-direction: column;
  padding: 197px 0 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-17xl);
}
.imageCrop1Icon,
.imageIcon {
  align-self: stretch;
  position: relative;
  max-height: 100%;
  object-fit: cover;
}
.imageCrop1Icon {
  width: 822px;
}
.imageIcon {
  flex: 1;
  max-width: 100%;
  overflow: hidden;
  display: none;
}
.frameGroup {
  flex: 1;
  height: 781px;
  display: flex;
  flex-direction: row;
  padding: 28px 0 0 113px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
  gap: 51px;
  z-index: 1;
}
.body,
.bodyChild {
  mix-blend-mode: normal;
}
.bodyChild {
  position: absolute;
  margin: 0 !important;
  width: 100%;
  right: 0;
  bottom: -0.5px;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  height: 476px;
  object-fit: cover;
  z-index: 0;
}
.body {
  align-self: stretch;
  background-color: var(--color-darkslategray-200);
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.01);
  height: 844px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-81xl) 0 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  font-size: 64px;
  color: var(--color-gray-100);
}
.contentify11 {
  position: absolute;
  top: 0;
  left: 0;
  width: 1152px;
  height: 395px;
  object-fit: cover;
}
.groupItem {
  position: absolute;
  top: 45.25px;
  left: -2.34px;
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  border-top: 1px solid var(--color-mediumaquamarine-100);
  border-right: 1px solid var(--color-mediumaquamarine-100);
  border-bottom: 5px solid var(--color-mediumaquamarine-100);
  border-left: 1px solid var(--color-mediumaquamarine-100);
  box-sizing: border-box;
  width: 306.1px;
  height: 81.54px;
  transform: rotate(-5.56deg);
  transform-origin: 0 0;
}
.audienceAnalyticsForContainer {
  position: absolute;
  top: 50.25px;
  left: 7.51px;
  display: flex;
  align-items: center;
  width: 281.99px;
  height: 63.28px;
  transform: rotate(-5.31deg);
  transform-origin: 0 0;
}
.rectangleParent {
  position: absolute;
  top: 162px;
  left: 268.49px;
  width: 314.8px;
  height: 125.95px;
  transform: rotate(2.96deg);
  transform-origin: 0 0;
}
.contentify11Parent {
  position: relative;
  width: 1152px;
  height: 395px;
}
.bodyInner,
.spyYourCompetitor {
  position: absolute;
  display: flex;
  align-items: center;
}
.bodyInner {
  top: calc(50% - 128.03px);
  left: calc(50% - 577px);
  flex-direction: column;
  justify-content: flex-start;
}
.spyYourCompetitor {
  width: calc(100% - 1074px);
  top: 81.23px;
  left: 536px;
  font-size: var(--font-size-25xl);
  line-height: 52px;
  font-family: var(--font-poppins);
  color: var(--color-gray-300);
  justify-content: center;
  height: 102px;
}
.body1 {
  align-self: stretch;
  position: relative;
  background-color: var(--color-whitesmoke-200);
  height: 689.35px;
  text-align: center;
  font-size: 29px;
  color: var(--color-mediumaquamarine);
  font-family: var(--font-roboto);
}
.contentify21 {
  position: relative;
  width: 1152px;
  height: 402px;
  object-fit: cover;
}
.contentify21Wrapper {
  position: absolute;
  top: calc(50% - 128.38px);
  left: calc(50% - 577px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.hotTopic {
  color: var(--color-mediumvioletred);
}
.span {
  color: var(--color-mediumblue);
}
.tailorContentsBasedContainer {
  position: absolute;
  width: calc(100% - 1074px);
  top: 76.3px;
  left: 536px;
  line-height: 52px;
  display: flex;
  align-items: center;
}
.body2 {
  align-self: stretch;
  position: relative;
  background-color: var(--color-whitesmoke-200);
  height: 689.35px;
  text-align: center;
  font-size: var(--font-size-25xl);
  color: var(--color-gray-300);
  font-family: var(--font-poppins);
}
.teamUpWithContainer,
.with870IntegrationsContainer {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
}
.teamUpWithContainer {
  top: 0;
  line-height: 40px;
  font-family: var(--font-poppins);
  width: 305.68px;
  height: 85px;
}
.with870IntegrationsContainer {
  top: 101px;
  font-size: var(--font-size-lg);
  line-height: 28px;
  width: 336.62px;
  height: 107px;
}
.viewAllApps {
  position: absolute;
  top: 231px;
  left: 5px;
  font-size: var(--font-size-base);
  line-height: 24px;
  font-weight: 500;
}
.div {
  position: absolute;
  top: 237px;
  left: 105.89px;
  font-size: var(--font-size-sm);
  line-height: 14px;
  font-family: var(--font-font-awesome-5-free);
  text-align: right;
}
.appsGroup768x366pngIcon {
  position: absolute;
  top: calc(50% - 137.74px);
  left: calc(50% - 76.19px);
  width: 584.38px;
  height: 278.48px;
  object-fit: cover;
}
.teamUpWithYourFavoriteAppParent {
  position: absolute;
  top: calc(50% - 143.53px);
  left: calc(50% - 510px);
  width: 1016.38px;
  height: 281.48px;
}
.body3 {
  align-self: stretch;
  position: relative;
  background-color: var(--color-whitesmoke-100);
  height: 352px;
  font-size: var(--font-size-13xl);
  color: var(--color-darkslategray-200);
}
.noCreditCard,
.tryItNow {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 48px;
  display: flex;
  align-items: center;
  width: 364.58px;
  height: 56px;
}
.noCreditCard {
  top: 70px;
  font-size: var(--font-size-lg);
  line-height: 28px;
  font-family: var(--font-ibm-plex-sans);
  width: 308.56px;
  height: 23px;
}
.emailAddress,
.tryItNowForFreeParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 364.58px;
  height: 93px;
}
.emailAddress {
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 126.31px;
  height: 25px;
}
.divplaceholder {
  position: absolute;
  top: 19.5px;
  left: 16px;
  width: 274.47px;
  height: 25px;
  overflow: hidden;
}
.inputacInput {
  position: absolute;
  width: calc(100% - 233.53px);
  top: 0;
  right: 233.53px;
  left: 0;
  border-radius: var(--br-8xs) 0 0 var(--br-8xs);
  background-color: var(--color-white);
  height: 64px;
}
.startYourFree {
  position: relative;
  line-height: 32px;
}
.buttonactButton2 {
  position: absolute;
  top: 0;
  left: 306.47px;
  border-radius: 0 var(--br-8xs) var(--br-8xs) 0;
  background-color: var(--color-mediumblue);
  display: flex;
  flex-direction: row;
  padding: var(--padding-base) 35.47999954223633px var(--padding-base)
    35.04999923706055px;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  color: var(--color-white);
}
.inputacInputParent {
  position: absolute;
  top: 26px;
  left: 540px;
  width: 540px;
  height: 64px;
  font-size: var(--font-size-lgi-2);
  color: var(--color-lightslategray);
  font-family: var(--font-ibm-plex-sans);
}
.frameContainer {
  position: absolute;
  top: calc(50% - 63.56px);
  left: calc(50% - 540px);
  width: 1080px;
  height: 93px;
}
.body4,
.footer {
  align-self: stretch;
  position: relative;
}
.body4 {
  background-color: var(--color-darkslategray-200);
  height: 216px;
  font-size: var(--font-size-21xl);
  font-family: var(--font-poppins);
}
.footer {
  height: 1119.95px;
}
.contentifyDraft20230521 {
  position: relative;
  background-color: var(--color-whitesmoke-300);
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.01);
  width: 100%;
  height: 11030px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-white);
  font-family: var(--font-ibm-plex-sans);
}
@media screen and (max-width: 1200px) {
  .login {
    display: none;
  }
}
@media screen and (max-width: 960px) {
  .vectorStrokeIcon {
    display: flex;
  }
  .empowerYourBusinessWithVirParent {
    width: 830px;
    flex: 1;
  }
  .imageCrop1Icon {
    display: none;
  }
  .imageIcon {
    flex: unset;
    align-self: stretch;
  }
  .body,
  .frameGroup {
    align-self: stretch;
    flex-direction: column;
    align-items: flex-start;
  }
  .frameGroup {
    flex: unset;
    height: auto;
    gap: var(--gap-0);
    justify-content: flex-start;
    padding-left: var(--padding-81xl);
    padding-top: 0;
    box-sizing: border-box;
  }
  .body {
    width: auto;
    flex: 1;
    gap: var(--gap-3xs);
    justify-content: space-between;
    border-radius: 0;
  }
}
@media screen and (max-width: 1188px) {
  .buttonactButtonWrapper {
    display: none;
  }
}
@media screen and (max-width: 1289px) {
  .buttonactButtonWrapper {
    display: none;
  }
}
@media screen and (max-width: 1493px) {
  .buttonactButtonWrapper {
    display: flex;
  }
}
@media screen and (max-width: 1148px) {
  .vectorStrokeIcon {
    display: none;
  }
}
@media screen and (max-width: 770px) {
  .buttonactButtonWrapper {
    display: none;
  }
}
@media screen and (max-width: 1410px) {
  .login {
    display: none;
  }
}
@media screen and (max-width: 1182px) {
  .catgoryWrapper {
    display: none;
  }
  .vectorStrokeIcon {
    display: flex;
  }
}

.startForFree {
  text-decoration: underline;
}
.startForFreeToday {
  color: inherit;
}
.heyThereFreeContainer {
  position: relative;
  line-height: 17.5px;
}
.sectionelementorSection,
.top {
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.01);
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.sectionelementorSection {
  flex: 1;
  background-color: var(--color-orange);
  height: 40px;
  align-items: center;
}
.top {
  align-self: stretch;
  background-color: var(--color-gray-700);
  height: 44px;
  align-items: flex-start;
  text-align: center;
  font-size: var(--font-size-xl);
  color: inherit;
  font-family: var(--font-ibm-plex-sans);
}
@media screen and (max-width: 1200px) {
  .sectionelementorSection {
    mix-blend-mode: normal;
    background-color: var(--color-orange-100);
    flex: 1;
    flex-direction: column;
    gap: var(--gap-0);
  }
  .top {
    gap: var(--gap-8xs);
  }
}
@media screen and (max-width: 960px) {
  .sectionelementorSection {
    display: none;
    width: auto;
    align-self: unset;
    height: auto;
    flex-direction: column;
  }
  .top {
    display: flex;
    gap: var(--gap-8xs);
  }
}
@media screen and (max-width: 420px) {
  .sectionelementorSection {
    display: none;
    width: auto;
    align-self: unset;
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-top: 0;
    box-sizing: border-box;
  }
  .top {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Font+Awesome+5+Free:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Font+Awesome+5+Brands:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-ibm-plex-sans: "IBM Plex Sans";
  --font-font-awesome-5-free: "Font Awesome 5 Free";
  --font-font-awesome-5-brands: "Font Awesome 5 Brands";
  --font-poppins: Poppins;
  --font-roboto: Roboto;
  --font-open-sans: "Open Sans";

  /* font sizes */
  --font-size-base: 16px;
  --font-size-sm: 14px;
  --font-size-lg: 18px;
  --font-size-7xl: 26px;
  --font-size-xs: 12px;
  --font-size-xl: 20px;
  --font-size-25xl: 44px;
  --font-size-lgi-2: 19.2px;
  --font-size-21xl: 40px;
  --font-size-13xl: 32px;
  --font-size-sm-6: 13.6px;
  --font-size-19xl: 38px;
  --font-size-29xl: 48px;
  --font-size-17xl: 36px;
  --font-size-16xl: 35px;

  /* Colors */
  --color-ghostwhite: #f7f9ff;
  --color-white: #fff;
  --color-gray-100: #fdfdfd;
  --color-gray-200: #909399;
  --color-gray-900: #252530;
  --color-gray-300: #212034;
  --color-gray-400: #1f2129;
  --color-gray-500: #212021;
  --color-gray-600: #00212c;
  --color-gray-800: rgba(255, 255, 255, 0);
  --color-gray-700: rgba(0, 33, 44, 0.95);
  --color-lightslategray: #9298ad;
  --color-dimgray: #4e5468;
  --color-silver: #c5c6ca;
  --color-aliceblue: #edf0f7;
  --color-gainsboro: #d9d9d9;
  --color-mediumblue: #004cff;
  --color-darkslategray-100: #003343;
  --color-darkslategray-200: #0c2b36;
  --color-whitesmoke-200: #f4f9f8;
  --color-whitesmoke-400: #f4f8f8;
  --color-whitesmoke-100: #f5f6f7;
  --color-whitesmoke-500: #f5f5f5;
  --color-whitesmoke-300: #f1f3f3;
  --color-mediumturquoise: #5acdbb;
  --color-lavender: #eaf0ff;
  --color-mediumaquamarine-100: #5acd9d;
  --color-mediumaquamarine: #19cca3;
  --color-mediumvioletred: #ec2eb0;
  --color-orange: #ffbe17;
  --color-orange-100: #ffbd16;
  --color-firebrick: #d0241b;

  /* Gaps */
  --gap-9xs: 4px;
  --gap-0: 0px;
  --gap-xs: 12px;
  --gap-16xl: 35px;
  --gap-4xl-5: 23.5px;
  --gap-11xs: 2px;
  --gap-2xl: 21px;
  --gap-lgi: 19px;
  --gap-96xl: 115px;
  --gap-3xl: 22px;
  --gap-3xs: 10px;
  --gap-xl: 20px;
  --gap-71xl: 90px;
  --gap-41xl: 60px;
  --gap-11xl: 30px;
  --gap-156xl: 175px;
  --gap-31xl: 50px;
  --gap-sm: 14px;
  --gap-5xs: 8px;
  --gap-4xl: 23px;
  --gap-7xl: 26px;
  --gap-lg: 18px;
  --gap-10xl: 29px;
  --gap-76xl: 95px;
  --gap-17xl: 36px;
  --gap-6xs: 7px;
  --gap-9xl: 28px;
  --gap-8xs: 5px;

  /* Paddings */
  --padding-13xl: 32px;
  --padding-61xl: 80px;
  --padding-4xs: 9px;
  --padding-lg-6: 18.6px;
  --padding-mid: 17px;
  --padding-132xl-4: 151.4px;
  --padding-5xs: 8px;
  --padding-base: 16px;
  --padding-smi-5: 12.5px;
  --padding-mini-5: 14.5px;
  --padding-10xs: 3px;
  --padding-59xl: 78px;
  --padding-8xl: 27px;
  --padding-274xl: 293px;
  --padding-12xl: 31px;
  --padding-54xl: 73px;
  --padding-12xs-4: 0.4px;
  --padding-12xs-2: 0.2px;
  --padding-46xl: 65px;
  --padding-43xl: 62px;
  --padding-3xs: 10px;
  --padding-11xs: 2px;
  --padding-22xl: 41px;
  --padding-11xl: 30px;
  --padding-25xl: 44px;
  --padding-16xl-5: 35.5px;
  --padding-16xl: 35px;
  --padding-4xl: 23px;
  --padding-85xl: 104px;
  --padding-17xl: 36px;
  --padding-32xl: 51px;
  --padding-53xl: 72px;
  --padding-44xl: 63px;
  --padding-2xl: 21px;
  --padding-70xl: 89px;
  --padding-76xl: 95px;
  --padding-64xl: 83px;
  --padding-181xl: 200px;
  --padding-116xl: 135px;
  --padding-xl: 20px;
  --padding-71xl: 90px;
  --padding-52xl: 71px;
  --padding-113xl: 132px;
  --padding-49xl: 68px;
  --padding-114xl: 133px;
  --padding-58xl: 77px;
  --padding-115xl: 134px;
  --padding-31xl: 50px;
  --padding-81xl: 100px;
  --padding-21xl: 40px;
  --padding-25xl-5: 44.5px;
  --padding-26xl: 45px;
  --padding-smi: 13px;
  --padding-10xl: 29px;
  --padding-xs: 12px;
  --padding-mini: 15px;
  --padding-7xs: 6px;
  --padding-sm: 14px;
  --padding-7xl-2: 26.2px;
  --padding-6xl: 25px;
  --padding-6xl-9: 25.9px;
  --padding-7xl: 26px;
  --padding-6xl-1: 25.1px;
  --padding-7xl-1: 26.1px;
  --padding-84xl: 103px;
  --padding-40xl: 59px;

  /* border radiuses */
  --br-9xs: 4px;
  --br-9xs-2: 3.2px;
  --br-8xs: 5px;
  --br-29xl: 48px;
  --br-3xs: 10px;
  --br-4xs: 9px;
  --br-10xs: 3px;
  --br-9xl: 28px;
}

.the1Content {
  position: absolute;
  width: calc(100% - 1074px);
  top: 66.58px;
  left: 537px;
  line-height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 111px;
}
.haveAnySpecific {
  margin: 0;
}
.haveAnySpecificContainer {
  position: relative;
  line-height: 20px;
}
.chatMessageFromActivecampai,
.span {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.span {
  padding: var(--padding-xs) var(--padding-7xl) var(--padding-xs)
    var(--padding-xs);
}
.chatMessageFromActivecampai {
  position: absolute;
  height: calc(100% - 19px);
  top: 9px;
  bottom: 10px;
  left: 26px;
  border-radius: var(--br-9xs);
  background-color: var(--color-white);
  box-shadow: 0 7px 6px 1px rgba(0, 0, 0, 0.16);
  width: 217px;
}
.closeDriftWidgetMessengerP {
  position: absolute;
  height: calc(100% - 43px);
  top: -8px;
  bottom: 51px;
  left: -10px;
  border-radius: var(--br-3xs);
  max-height: 100%;
  width: 40px;
}
.image4Icon {
  position: absolute;
  top: 0.23px;
  left: 0;
  width: 58px;
  height: 56px;
  object-fit: cover;
}
.divdriftWidgetAvatar {
  top: 0;
  left: 0;
  width: 56px;
  height: 56px;
  background-image: url(/public/divdriftwidgetavatar@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.divdriftWidgetAvatar,
.divdriftWidgetControllerIc,
.divdriftWidgetRecipientAva {
  position: absolute;
  border-radius: var(--br-9xl);
  overflow: hidden;
}
.divdriftWidgetRecipientAva {
  top: calc(50% - 28px);
  left: calc(50% - 28px);
  background-color: var(--color-white);
  width: 56px;
  height: 56px;
}
.divdriftWidgetControllerIc {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-mediumblue);
}
.div {
  position: absolute;
  top: 0;
  left: 5.56px;
  line-height: 18px;
  display: flex;
  align-items: center;
  width: 7.08px;
  height: 17px;
}
.divdriftControllerIconUnre {
  position: absolute;
  top: -5px;
  right: -5px;
  border-radius: var(--br-4xs);
  background-color: var(--color-firebrick);
  width: 18px;
  height: 18px;
}
.openChatWithActivecampaign {
  position: absolute;
  height: calc(100% - 27px);
  top: 27px;
  bottom: 0;
  left: 286px;
  border-radius: var(--br-9xl);
  background-color: var(--color-gray-800);
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.16);
  width: 56px;
  overflow: hidden;
  font-size: var(--font-size-xs);
  color: var(--color-white);
}
.chatMessageFromActivecampaiParent {
  position: absolute;
  height: calc(100% - 336px);
  top: 185.33px;
  bottom: 150.67px;
  left: 1167px;
  width: 342px;
}
.contentify251 {
  position: absolute;
  top: 0;
  left: calc(50% - 754.5px);
  width: 1150px;
  height: 419px;
  object-fit: cover;
}
.groupChild,
.spy100cContents {
  position: absolute;
  transform-origin: 0 0;
}
.groupChild {
  top: 24.83px;
  left: 0;
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  border-top: 1px solid var(--color-mediumvioletred);
  border-right: 1px solid var(--color-mediumvioletred);
  border-bottom: 5px solid var(--color-mediumvioletred);
  border-left: 1px solid var(--color-mediumvioletred);
  box-sizing: border-box;
  width: 207px;
  height: 59.27px;
  transform: rotate(-6.89deg);
}
.spy100cContents {
  top: 40px;
  left: 12.66px;
  transform: rotate(-6.53deg);
}
.groupWrapper,
.rectangleParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 212.61px;
  height: 83.67px;
}
.groupWrapper {
  top: 251px;
  left: 745px;
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--color-mediumvioletred);
  font-family: var(--font-roboto);
}
.bodyInner,
.groupParent {
  position: absolute;
  width: 1509px;
}
.groupParent {
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
}
.bodyInner {
  bottom: 66.77px;
  left: calc(50% - 577px);
  height: 419px;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-gray-500);
  font-family: var(--font-open-sans);
}
.body {
  align-self: stretch;
  position: relative;
  background-color: var(--color-whitesmoke-200);
  height: 689.35px;
  text-align: center;
  font-size: var(--font-size-25xl);
  color: var(--color-gray-300);
  font-family: var(--font-poppins);
}
